import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Image,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Column,
  FormItem,
  TextInput,
} from "@ubirider/pick-component-library";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { updateName } from "../../../api/user";

import closeButton from "../../../assets/cross.svg";
import checkWhiteIcon from "../../../assets/check_circle_white.svg";
import closeWhiteIcon from "../../../assets/cross_white_24.svg";
import arrowBackIcon from "../../../assets/arrow_left.svg";
import editIcon from "../../../assets/edit_purple.svg";

export function ChangeNameModal(props) {
  const { t } = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const toastIdRef = useRef();

  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.name,
    },
  });

  const name = watch("name");

  useEffect(() => {
    reset({
      name: props.name,
    });
  }, [props.isOpen, props.onClose, reset]);

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  const onSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    await updateName(name);
    props.setNameChange(true);
    setLoading(false);
    handleChange();
    props.onClose();
  };

  const handleChange = () => {
    toastIdRef.current = toast({
      position: "top",
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box
          alignItems="center"
          color="white"
          bg="#01AE70"
          border="1px solid #067F5B"
          boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)"
          borderRadius="4px"
        >
          <Flex justifyContent="space-between" gap="16px" padding="16px">
            <Image src={checkWhiteIcon} />
            <Text fontSize="14px" fontWeight="600">
              {t("account.name_changed_toast")}
            </Text>
            <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    });
    props.onClose();
    setLoading(false);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: "full", md: "full", lg: "xl", xl: "xl" }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px" }}
      >
        <Flex
          direction="column"
          gap="24px"
          mt={{ sm: "67px", md: "67px", lg: "0px", xl: "0px" }}
        >
          {windowSize.current[0] < 960 && (
            <Flex alignItems="flex-start">
              <UbiButton
                onClick={props.onClose}
                style="ghost"
                type="neutralLight"
                label={t("authentication.back")}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: "0" }}
              />
            </Flex>
          )}

          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontWeight="700" fontSize="24px" flex="1">
              {t("account.change_name")}
            </Text>
            <Flex
              display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
              as="button"
              onClick={props.onClose}
            >
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Column
            css={{ gap: "24px", width: "100%" }}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <FormItem
                  label={t("authentication.your_name")}
                  error={errors.name}
                >
                  <TextInput
                    placeholder={t("authentication.your_name")}
                    {...field}
                  />
                </FormItem>
              )}
            />

            <UbiButton
              ref={initialRef}
              submit
              disabled={!name}
              loading={loading}
              style="solid"
              type="secondary"
              label={t("common.save")}
              css={{ alignSelf: "stretch" }}
            />
          </Column>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
