import {
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { setDefaultBillingInfo } from "../../../../api/billing";
import { AuthContext } from "../../../../AuthProvider";
import { SuccessModal } from "../../../Modals/SuccessModal/SuccessModal";
import { DeleteProfileModal } from "../../../Modals/DeleteProfileModal/DeleteProfileModal";
import { EditBillingModal } from "../../../Modals/EditBillingModal/EditBillingModal";

import checkIcon from "../../../../assets/check.svg";
import threeDots from "../../../../assets/three_dots.svg";

export const OptionsSelector = (props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const context = useContext(AuthContext);
  const {
    isOpen: isOpenDP,
    onOpen: onOpenDP,
    onClose: onCloseDP,
  } = useDisclosure();
  const {
    isOpen: isOpenSM,
    onOpen: onOpenSM,
    onClose: onCloseSM,
  } = useDisclosure();

  const setDefault = async (id) => {
    console.log(id);
    await setLoading(true);
    try {
      setDefaultBillingInfo(id).then((docRef) => {
        console.log("Entire Document has been updated successfully");
        setLoading(false);
        context.setRefresh(!context.refresh);
      });
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton padding="0 8px" aria-label="Options" variant="outline">
          <Image src={threeDots} />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={onOpen}>{t("billing_information.edit")}</MenuItem>
          <MenuItem onClick={onOpenDP}>{t("common.delete")}</MenuItem>
          <MenuItem
            justifyContent="space-between"
            _loading={loading}
            onClick={() => {
              setDefault(props.id);
            }}
          >
            {t("billing_information.set_as_default")}
            <Image
              display={props.info.default ? "flex" : "none"}
              src={checkIcon}
            />
          </MenuItem>
        </MenuList>
      </Menu>
      <EditBillingModal
        id={props.id}
        info={props.info}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
      />
      <DeleteProfileModal
        id={props.id}
        onOpen={onOpenDP}
        onClose={onCloseDP}
        isOpen={isOpenDP}
        onOpenSM={onOpenSM}
      />
      <SuccessModal
        onOpen={onOpenSM}
        onClose={onCloseSM}
        isOpen={isOpenSM}
        description={t("billing_information.billing_information_deleted")}
      />
    </>
  );
};
