import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@ubirider/pick-component-library";
import { VStack } from "@chakra-ui/layout";
import { signOut } from "firebase/auth";
import firebase from "../../../../../Firebase";
import { useTranslation } from "react-i18next";
import { RouteButton } from "../../Menu/components/RouteButton";

import menuButtonIcon from "../../../../../assets/hamburger.svg";
import menuButtonRedDotIcon from "../../../../../assets/hamburger_red_dot.svg";
import closeIcon from "../../../../../assets/cross.svg";
import homeIcon from "../../../../../assets/home.svg";
import travelIcon from "../../../../../assets/menu.svg";
import billingIcon from "../../../../../assets/billing.svg";
import faqsIcon from "../../../../../assets/question-mark.svg";
import accountIcon from "../../../../../assets/settings.svg";
import supportIcon from "../../../../../assets/message-circle.svg";
import notificationIcon from "../../../../../assets/path.svg";
import editIcon from "../../../../../assets/edit.svg";

export const MenuButton = () => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const logout = async () => {
    await signOut(firebase);
  };

  return (
    <>
      <Button
        style="ghost"
        type="neutralDark"
        size="small"
        leftIconProps={isOpen ? { src: closeIcon } : { src: menuButtonIcon }}
        onClick={isOpen ? onClose : onOpen}
      />
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          padding="16px 24px 24px"
          mt="68px"
          minW="calc(100vw)"
          gap="24px"
          css={"box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)"}
        >
          <Flex direction="column" align="flex-start">
            <RouteButton
              icon={homeIcon}
              label="home.home"
              route="/home"
              onClose={onClose}
            />

            <RouteButton
              icon={travelIcon}
              label="travel_history.travel_history"
              route="/travel-history"
              onClose={onClose}
            />

            <RouteButton
              icon={billingIcon}
              label="billing_information.billing_information"
              route="/billing-information"
              onClose={onClose}
            />
            {/*
                        <RouteButton icon{notificationIcon} label="notifications" route="/notifications" onClose={onClose} />
                        */}
          </Flex>

          <Flex direction="column" align="flex-start">
            <RouteButton
              icon={accountIcon}
              label="account.account"
              route="/account"
              onClose={onClose}
            />

            <RouteButton
              icon={faqsIcon}
              label="faqs.faqs"
              route="/faqs"
              onClose={onClose}
            />

            <RouteButton
              icon={supportIcon}
              label="customer_support.customer_support"
              route="/support"
              onClose={onClose}
            />
          </Flex>

          <Button
            onClick={() => {
              logout();
            }}
            style="solid"
            type="secondary"
            label={t("home.logout_button")}
            css={{ width: "100%" }}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
