import { Box, Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "@ubirider/pick-component-library";

export default function NifComponent({ isChecked, onChange, info }) {
  return (
      <Box
          border="1px"
          borderColor="neutural.600"
          rounded="4px"
          p="16px"
          bg="neutral.50"
          marginBottom="8px"
          cursor="pointer"
          onClick={onChange}
      >
        <Flex gap="36px" align="flex-start">
          <Checkbox
              checked={isChecked}
              onChange={onChange}
              css={{marginTop: "5px"}}
          />
          {/*
          <Checkbox
              mt="6px"
              size="lg"
              colorScheme="secondary"
              isChecked={isChecked}
              onChange={onChange}
              pl="8px"
          />
        />*/}
          <Flex direction="column">
            <Text fontWeight="700" fontSize="16px" color="neutral.600">
              {info.profileName}
            </Text>
            <Text fontWeight="400" fontSize="16px" color="neutral.600">
              {info.nif}
            </Text>
          </Flex>
        </Flex>
      </Box>
  );
}
