import { Divider, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OptionsSelector } from "./OptionsSelector";
export const BillingProfile = (props) => {
  const { t } = useTranslation();
  return (
    <Flex
      bg={props.info.default ? "secondary.50" : "neutral.25"}
      rounded="4px"
      css={"box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)"}
      border="1px"
      borderColor={props.info.default ? "secondary.500" : "neutral.300"}
      paddingInline="16px"
      paddingBlock="8px"
      direction="column"
      gap="8px"
    >
      <Flex justify="space-between" align="center">
        <Text fontWeight="bold">{props.info.profileName}</Text>
        <OptionsSelector id={props.id} info={props.info} />
      </Flex>
      <Divider />
      <Text>{props.info.name}</Text>
      <Text>
        {t("common.tax_id")} {props.info.nif}
      </Text>
    </Flex>
  );
};
