import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    Text,
    Flex,
} from "@chakra-ui/react";
import { Button as UbiButton } from "@ubirider/pick-component-library";
import { useTranslation } from "react-i18next";

export function TooltipModal(props) {
    const { t } = useTranslation();

    return (
        <AlertDialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            autoFocus={false}
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent w="250px" bg="secondary.500" border="1px" borderColor="secondary.600">
                    <Flex gap="24px" direction="column">
                        <Text p="24px 18px 0 18px" align="center" fontSize="13px" color="secondary.25">
                            {props.text}
                        </Text>
                        <Flex p="0 24px 24px 24px" align="center">
                            <UbiButton
                                onClick={props.onClose}
                                style="outline"
                                type="primary"
                                label={t("authentication.got_it")}
                                css={{ width: "100%" }}
                            />
                        </Flex>
                    </Flex>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
