import {Flex, Image, Text} from "@chakra-ui/react";
import { Label } from "@ubirider/pick-component-library";
import { useTranslation } from "react-i18next";

import alertIcon from "../../../../../../../../assets/alert_white.svg";
import checkIcon from "../../../../../../../../assets/check_white_16.svg";
import clockIcon from "../../../../../../../../assets/clock_yellow.svg";

export function PaymentStatus(props) {
  const { t } = useTranslation();
  
  if (props.code === 0) {
    return (
        <Flex>
            <Flex display={{ sm: "none", md: "flex", lg: "flex", xl: "flex" }}>
                <Label
                    type="success"
                    style="outline"
                    label={`${t(`travel_history.paid`)}`}
                    css={{width: "130px"}}
                />
            </Flex>
            <Flex
                display={{sm: "flex", md: "none", lg: "none", xl: "none" }}
                bg="success.600"
                border="1px solid #06674B"
                borderRadius="4px"
                padding="0 8px"
                gap="4px"
            >
                <Image src={checkIcon}/>
                <Text display={props.isExpanded ? "flex" : "none"} fontWeight="700" fontSize="13px" color="white" textTransform="uppercase">
                    {t(`travel_history.paid`)}
                </Text>
            </Flex>
        </Flex>
    );
  }
  if (props.code === 1) {
    return (
        <Flex>
            <Flex display={{ sm: "none", md: "flex", lg: "flex", xl: "flex" }}>
                <Label
                    type="warning"
                    style="outline"
                    label={`${t(`travel_history.pending`)}`}
                    css={{width: "130px"}}
                />
            </Flex>
            <Flex
                display={{sm: "flex", md: "none", lg: "none", xl: "none" }}
                bg="warning.400"
                border="1px solid #C6901E"
                borderRadius="4px"
                padding="0 8px"
                gap="4px"
            >
                <Image src={clockIcon}/>
                <Text display={props.isExpanded ? "flex" : "none"} fontWeight="700" fontSize="13px" color="#91600E" textTransform="uppercase">
                    {t(`travel_history.pending`)}
                </Text>
            </Flex>
        </Flex>
    );
  }  if (props.code === 2) {
    return (
        <Flex>
            <Flex display={{ sm: "none", md: "flex", lg: "flex", xl: "flex" }}>
                <Label
                    type="danger"
                    style="outline"
                    label={`${t(`travel_history.not_accepted`)}`}
                    css={{width: "130px"}}
                />
            </Flex>
            <Flex
                display={{sm: "flex", md: "none", lg: "none", xl: "none" }}
                bg="danger.600"
                border="1px solid #B42318"
                borderRadius="4px"
                padding="0 8px"
                gap="4px"
            >
                <Image src={alertIcon}/>
                <Text display={props.isExpanded ? "flex" : "none"} fontWeight="700" fontSize="13px" color="white" textTransform="uppercase">
                    {t(`travel_history.not_accepted`)}
                </Text>
            </Flex>
        </Flex>
    );
  } if (props.code === 3) {
    return (
        <Flex>
            <Flex display={{ sm: "none", md: "flex", lg: "flex", xl: "flex" }}>
                <Label
                    type="secondary"
                    style="outline"
                    label={`${t(`travel_history.canceled`)}`}
                    css={{width: "130px"}}
                />
            </Flex>
            <Flex
                display={{sm: "flex", md: "none", lg: "none", xl: "none" }}
                bg="secondary.600"
                border="1px solid #475467"
                borderRadius="4px"
                padding="0 8px"
                gap="4px"
            >
                <Image src={checkIcon}/>
                <Text display={props.isExpanded ? "flex" : "none"} fontWeight="700" fontSize="13px" color="white" textTransform="uppercase">
                    {t(`travel_history.canceled`)}
                </Text>
            </Flex>
        </Flex>
    );
  }
}
