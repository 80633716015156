import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const auth = useContext(AuthContext);

  if (!auth.user) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }

  // authorized so return child components
  return children;
}
