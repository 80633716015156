import { Flex, Spinner, useDisclosure } from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import { Menu } from "./pages/Home/components/Menu";
import { TopBar } from "./pages/Home/components/TopBar";
import { useContext, useEffect, useState } from "react";
import { getUserData, updateEmail, updateTermsConditions } from "./api/user";
import { AuthContext } from "./AuthProvider";
import { TermsConditionsModal } from "./pages/Modals/TermsConditionsModal/TermsConditionsModal";
import { signOut } from "firebase/auth";
import firebase from "./Firebase";
import i18next from "i18next";

export function Layout() {
  const navigate = useNavigate();
  const Auth = useContext(AuthContext);
  const userLang = navigator.language
    .substring(0, navigator.language.indexOf("-"))
    .toUpperCase();
  const [terms, setTerms] = useState(null);
  const [lang, setLang] = useState(null);
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTC, setLoadingTC] = useState(false);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      if (terms === null && lang === null) {
        setLoading(true);
        const user = await getUserData();
        console.log(user);
        setLoading(false);
        if (!user?.email) {
          await updateEmail(Auth.user.email);
        }
        setTerms(user?.terms ? user.terms : null);
        setLang(user?.lang ? user.lang : userLang);
        setName(user?.name ? user.name : null);
        if (user?.lang) {
          await i18next.changeLanguage(user.lang);
        }
        if (!user?.terms) {
          onOpen();
        }
      }
    };

    if (Auth.user) fetchData();
  }, [Auth.user, navigate, onOpen, isOpen, onClose]);

  async function handleAgree() {
    setLoadingTC(true);
    await updateTermsConditions();
    setLoadingTC(false);
    setTerms(true);
    onClose();
  }

  async function handleDeny() {
    await signOut(firebase);
  }

  return (
    <>
      {loading && (
        <Flex
          align="center"
          justify="center"
          minH="calc(100vh)"
          minW="calc(100vw)"
        >
          <Spinner
            thickness="10px"
            color="neutral.500"
            width="100px"
            height="100px"
          />
        </Flex>
      )}
      {(terms || window.location.pathname === "/faqs") && (
        <Flex
          direction="column"
          gap={{ sm: "48px", md: "41px", lg: "39px", xl: "39px" }}
          pt={{ sm: "0px", md: "0px", lg: "19px", xl: "19px" }}
          pl={{ sm: "24px", md: "48px", lg: "62px", xl: "130px", xxl: "464px" }}
          pr={{ sm: "24px", md: "48px", lg: "62px", xl: "130px", xxl: "464px" }}
          pb="36px"
          flex="1"
          as="main"
          mt={{ sm: "86px", md: "86px", lg: "86px", xl: "86px" }}
        >
          <TopBar name={name} />
          <Flex
            gap={{ sm: "0px", md: "0px", lg: "20px", xl: "20px" }}
            direction={{ md: "row", sm: "column" }}
          >
            <Menu />
            <Outlet />
          </Flex>
        </Flex>
      )}
      {!terms && window.location.pathname !== "/faqs" && (
        <TermsConditionsModal
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
          handleAgree={handleAgree}
          handleDeny={handleDeny}
          loading={loadingTC}
        />
      )}
    </>
  );
}
