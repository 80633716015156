import {
    Flex,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import {Button as UbiButton} from "@ubirider/pick-component-library";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { IconWithinCircle } from "../../Common/IconWithinCircle";

import deleteImage from "../../../assets/cross_white_24.svg";
import closeButton from "../../../assets/cross.svg";
import {getAuth, unlink} from "firebase/auth";

export const UnlinkModal = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)

    function onUnlink() {
        setLoading(true)
        const auth = getAuth()
        const user = auth.currentUser;
        const provider = props.provider === 'Google' ? 'google.com' : props.provider === 'Facebook' ? 'facebook.com' : props.provider === 'Apple' ? 'apple.com' : null
        unlink(user, provider)
            .then(function() {
                props.setProvider(false)
                setLoading(false)
                props.onClose()
            })
            .catch(function(error) {
                console.log(error)
                setLoading(false)
                props.onClose()
            });
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            size={{sm: "xs", md: "xl", lg: "xl", xl: "xl"}}
            closeOnOverlayClick={false}
            autoFocus={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent padding={{sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px"}}>
                <Flex direction="row" justifyContent="flex-end" alignItems="center">
                    <Flex as="button" onClick={props.onClose}>
                        <Image src={closeButton} />
                    </Flex>
                </Flex>
                <Flex direction="column" gap="48px" align="center">
                    <Flex direction="column" gap="16px" align="center">
                        <IconWithinCircle color="danger.600" icon={deleteImage} />
                        <Flex direction="column" gap="8px" align="center">
                            <Text
                                fontWeight="700"
                                fontSize={{sm: "20px", md: "20px", lg: "24px", xl: "24px"}}
                                color="neutral.800"
                                align="center"
                            >
                                {t("authentication.unlink_account")}
                            </Text>
                            <Text
                                fontWeight="600"
                                fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}}
                                color="neutral.600"
                                align="center"
                                width={{md: "380px", lg: "380px", xl: "380px"}}
                            >
                                {t("authentication.unlink_account_description", {provider: props.provider})}
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex gap="16px" display={{sm: "none", md: "flex", lg: "flex", xl: "flex"}}>
                        <UbiButton
                            onClick={props.onClose}
                            style="outline"
                            type="secondary"
                            label={t("common.cancel")}
                            css={{width: "98px"}}
                        />
                        <UbiButton
                            onClick={onUnlink}
                            loading={loading}
                            style="solid"
                            type="danger"
                            label={t("home.remove")}
                            css={{width: "98px"}}
                        />
                    </Flex>
                    <Flex gap="16px" alignSelf="stretch" direction="column" display={{sm: "flex", md: "none", lg: "none", xl: "none"}}>
                        <UbiButton
                            onClick={onUnlink}
                            loading={loading}
                            style="solid"
                            type="danger"
                            label={t("home.remove")}
                            css={{width: "100%"}}
                        />
                        <UbiButton
                            onClick={props.onClose}
                            style="outline"
                            type="secondary"
                            label={t("common.cancel")}
                            css={{width: "100%"}}
                        />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
};
