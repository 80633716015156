import {
  Box,
  Center,
  Flex,
  Grid,
  Image,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Text as UbiText,
  Column,
} from "@ubirider/pick-component-library";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../AuthProvider";
import { getBillingInfo } from "../../api/billing";

import { NewBillingModal } from "../Modals/NewBillingModal/NewBillingModal";
import { BillingProfile } from "./BillingProfile";

import emptyStateIllustration from "../../assets/illustration_billing.svg";
import plusIcon from "../../assets/plus_white.svg";
import editIcon from "../../assets/edit.svg";

export const BillingInfo = () => {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [billingInfo, setBillinginfo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBilling = async () => {
      const billing = await getBillingInfo();

      setBillinginfo(billing);
      setLoading(false);
    };
    console.log(billingInfo);
    getBilling();
    if (context.user) getBilling();
  }, [isOpen, context.refresh, context.user]);

  return (
    <Box flex="1">
      <Flex
        bg={{ sm: "", md: "neutral.100", lg: "neutral.100", xl: "neutral.100" }}
        rounded="8px"
        padding={{ sm: "", md: "24px", lg: "24px", xl: "24px" }}
      >
        <Column css={{ gap: "16px", flex: "1" }}>
          <UbiText type="displayXS" weight="bold">
            {t("billing_information.billing_information")}
          </UbiText>
          {loading ? (
            <Center minH="240px">
              <Spinner thickness="6px" color="neutral.500" size="xl" />
            </Center>
          ) : billingInfo != null && billingInfo.length > 0 ? (
            <Column css={{ gap: "24px" }}>
              <UbiText type="textSM">
                {t("billing_information.billing_information_description")}
              </UbiText>
              <Flex>
                <UbiButton
                  onClick={() => {
                    onOpen();
                  }}
                  style="ghost"
                  type="primary"
                  leftIconProps={{ src: plusIcon }}
                  label={t("billing_information.add_new_billing_information")}
                  css={{ padding: "16px 4px" }}
                />
              </Flex>

              <Grid
                templateColumns={{
                  sm: "repeat(1, 382px)",
                  md: "repeat(2, 382px)",
                  lg: "repeat(2, 382px)",
                  xl: "repeat(2, 382px)",
                  xxl: "repeat(3, 382px)",
                }}
                gap={{ sm: "24px", md: "16px", lg: "16px", xl: "16px" }}
              >
                {billingInfo.map((data, id) => (
                  <BillingProfile id={id} info={data} />
                ))}
              </Grid>
            </Column>
          ) : (
            <Flex
              direction="column"
              my={{ sm: "", md: "48px", lg: "48px", xl: "48px" }}
              mx={{ sm: "", md: "96px", lg: "96px", xl: "96px" }}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              gap="24px"
            >
              <Image src={emptyStateIllustration} height="112px" />
              <UbiText type="textSM" css={{ textAlign: "center" }}>
                {t("billing_information.billing_information_description")}
              </UbiText>
              <Center>
                <UbiButton
                  onClick={() => {
                    onOpen();
                  }}
                  style="solid"
                  type="primary"
                  leftIconProps={{ src: plusIcon }}
                  label={t("billing_information.add_new_billing_information")}
                />
              </Center>
            </Flex>
          )}
        </Column>
        <NewBillingModal onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      </Flex>
    </Box>
  );
};
