import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconWithinCircle } from "../../Common/IconWithinCircle";
import { Button as UbiButton } from "@ubirider/pick-component-library";
import deleteImage from "../../../assets/trash_white.svg";
import closeButton from "../../../assets/cross.svg";
import { AuthContext } from "../../../AuthProvider";
import { updateEmail } from "../../../api/user";

export function DeleteContactEmailModal(props) {
  const { t } = useTranslation();
  const Auth = useContext(AuthContext).user;

  const handleClickDelete = async () => {
    await updateEmail(Auth.email);
    props.fieldChange(true);
    props.onClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      size={{ sm: "xs", md: "xl", lg: "xl", xl: "xl" }}
      closeOnOverlayClick={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px" }}
      >
        <Flex direction="row" justifyContent="flex-end" alignItems="center">
          <Flex as="button" onClick={props.onClose}>
            <Image src={closeButton} />
          </Flex>
        </Flex>
        <Flex direction="column" gap="48px" align="center">
          <Flex direction="column" gap="16px" align="center">
            <IconWithinCircle color="danger.600" icon={deleteImage} />
            <Flex direction="column" gap="8px" align="center">
              <Text
                fontWeight="700"
                fontSize={{ sm: "20px", md: "20px", lg: "24px", xl: "24px" }}
                color="neutral.800"
                align="center"
              >
                {t("customer_support.delete_contact_email_title")}
              </Text>
              <Text
                fontWeight="600"
                fontSize={{ sm: "14px", md: "14px", lg: "16px", xl: "16px" }}
                color="neutral.600"
                align="center"
                width={{ md: "380px", lg: "380px", xl: "380px" }}
              >
                {t("customer_support.delete_contact_email_subtitle", {
                  contact_email: props.email,
                })}
              </Text>
            </Flex>
          </Flex>

          <Flex
            gap="16px"
            display={{ sm: "none", md: "flex", lg: "flex", xl: "flex" }}
          >
            <UbiButton
              onClick={props.onClose}
              style="outline"
              type="secondary"
              label={t("common.cancel")}
              css={{ width: "160px" }}
            />
            <UbiButton
              onClick={handleClickDelete}
              style="solid"
              type="danger"
              label={t("common.delete")}
              css={{ width: "160px" }}
            />
          </Flex>
          <Flex
            gap="16px"
            alignSelf="stretch"
            direction="column"
            display={{ sm: "flex", md: "none", lg: "none", xl: "none" }}
          >
            <UbiButton
              onClick={handleClickDelete}
              style="solid"
              type="danger"
              label={t("common.delete")}
              css={{ width: "100%" }}
            />
            <UbiButton
              onClick={props.onClose}
              style="outline"
              type="secondary"
              label={t("common.cancel")}
              css={{ width: "100%" }}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
