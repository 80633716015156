import React, {useRef, useState} from "react";
import {Box, Flex, Grid, Image, Divider} from "@chakra-ui/react";
import { Text } from "@ubirider/pick-component-library";
import { useTranslation } from "react-i18next";

import arrowRight from "../../../../assets/arrow_right_grey.svg";
import dotEmpty from "../../../../assets/dot_empty.svg";
import dotFull from "../../../../assets/dot_full.svg";
import alertYellow from "../../../../assets/alert_yellow.svg";
import alertRed from "../../../../assets/alert_red.svg";
import infoIcon from "../../../../assets/information_grey.svg";
import validatorExit from "../../../../assets/validator_exit.svg";
import validatorHand from "../../../../assets/validator_hand.svg";
import validatorCard from "../../../../assets/validator_card.svg";
import validatorError from "../../../../assets/validator_error.svg";
import validatorCross from "../../../../assets/validator_cross.svg";
import trainSpeeding from "../../../../assets/train_speeding.svg";
import cardIcon from "../../../../assets/contacless_card.svg";
import letterIcon from "../../../../assets/letter.svg";

export const HowToUseFaq = (props) => {
    const { t } = useTranslation();
    const scrollRef = useRef(null);

    const [scroll1, updateScroll1] = useState(0);
    const [scroll2, updateScroll2] = useState(0);
    const [scroll3, updateScroll3] = useState(0);
    const [scroll4, updateScroll4] = useState(0);

    const handleFirstSlide = (event) => {
        updateScroll1(event.currentTarget.scrollLeft)
    };

    const handleSecondSlide = (event) => {
        updateScroll2(event.currentTarget.scrollLeft)
    };

    const handleThirdSlide = (event) => {
        updateScroll3(event.currentTarget.scrollLeft)
    };

    const handleFourthSlide = (event) => {
        updateScroll4(event.currentTarget.scrollLeft)
    };

    return (
        <Flex marginBlock="16px" direction="column" gap="24px" justify="center">
            <Flex gap="10px">
                <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                    &bull;
                </Text>
                <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                    {t("custom_faqs.how_to_use_system")}
                </Text>
            </Flex>

            <Flex
                direction="column"
                overflow="hidden"
                mr="-24px"
                ref={scrollRef}
                align="center"
            >
                <Grid
                    templateColumns="repeat(9, 1fr)"
                    overflowX="scroll"
                    maxW="calc(100vw)"
                    gap="8px"
                    pr="90px"
                    pl={{sm: "40px", sm2: "70px", sm3: "90px"}}
                    justify="center"
                    sx={{ '::-webkit-scrollbar':{ display:'none' }, scrollSnapType: "x mandatory" }}
                    onScroll={handleFirstSlide}
                >
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image justify="flex-start" w="160px" src={validatorHand} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_system_step_1")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="24px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image justify="flex-start" w="160px" src={validatorCard} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_system_step_2")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image justify="flex-start" w="160px" src={trainSpeeding} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_system_step_3")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image justify="flex-start" w="52px" src={validatorExit} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_system_step_4")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image justify="flex-start" w="52px" src={cardIcon} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_system_step_5")}
                        </Text>
                    </Flex>
                </Grid>
            </Flex>

            <Flex align="center" justify="center">
                <Image src={scroll1 < 200 ? dotFull : dotEmpty} />
                <Image src={scroll1 >= 200 && scroll1 < 449 ? dotFull : dotEmpty} />
                <Image src={scroll1 >= 450 && scroll1 < 649 ? dotFull : dotEmpty} />
                <Image src={scroll1 >= 650 && scroll1 < 849 ? dotFull : dotEmpty} />
                <Image src={scroll1 >= 850 ? dotFull : dotEmpty} />
            </Flex>

            <Flex
                direction="column"
                align="center"
                bg="warning.100"
                border="1px"
                borderColor="warning.200"
                rounded="4px"
                gap="16px"
                padding="16px"
            >
                <Flex justify="flex-start" align="flex-start" gap="16px">
                    <Image alignSelf="flex-start" src={alertYellow} />
                    <Flex direction="column" gap="16px">
                        <Text type="textSM" weight="bold" css={{color: "#91600E"}}>
                            {t("custom_faqs.how_to_use_system_exit_validation_title")}
                        </Text>
                        <Text type="textSM" css={{color: "$warning600"}}>
                            {t("custom_faqs.how_to_use_system_exit_validation_description")}
                        </Text>
                    </Flex>
                </Flex>

                <Flex direction="column" justify="center" align="center" gap="24px">
                    <Flex direction="column" justify="center" align="center" gap="8px">
                        <Image src={validatorExit} />
                        <Flex direction="column">
                            <Text type="textXXS" weight="semibold" css={{color: "#067F5B", textAlign: "center"}}>
                                {t("custom_faqs.how_to_use_system_exit_validation_validate")}
                            </Text>
                            <Text type="textXXS" weight="bold">
                                {t("custom_faqs.how_to_use_system_exit_validation_gate")}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex direction="column" justify="center" align="center" gap="8px">
                        <Image  src={validatorCross} />
                        <Flex direction="column">
                            <Text type="textXXS" weight="semibold" css={{color: "#D23636", textAlign: "center"}}>
                                {t("custom_faqs.how_to_use_system_exit_validation_do_not_validate")}
                            </Text>
                            <Text type="textXXS" weight="bold">
                                {t("custom_faqs.how_to_use_system_exit_validation_vertical")}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Divider border="1px" borderColor="secondary.500" />

            <Flex direction="column" justify="center" gap="24px">
                <Flex gap="10px">
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        &bull;
                    </Text>
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        {t("custom_faqs.how_to_use_middle_journey")}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                overflow="hidden"
                mr="-24px"
                align="center"
            >
                <Grid
                    templateColumns="repeat(7, 1fr)"
                    overflowX="scroll"
                    maxW="calc(100vw)"
                    gap="8px"
                    pr="90px"
                    pl={{sm: "40px", sm2: "70px", sm3: "90px"}}
                    justify="center"
                    sx={{ '::-webkit-scrollbar':{ display:'none' } }}
                    onScroll={handleSecondSlide}
                >
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="160px" src={trainSpeeding} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_middle_journey_step_1")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="160px" src={validatorHand} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_middle_journey_step_2")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="160px" src={trainSpeeding} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_middle_journey_step_3")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="62px" src={cardIcon} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_middle_journey_step_4")}
                        </Text>
                    </Flex>
                </Grid>
            </Flex>

            <Flex align="center" justify="center">
                <Image src={scroll2 < 200 ? dotFull : dotEmpty} />
                <Image src={scroll2 >= 200 && scroll2 < 449 ? dotFull : dotEmpty} />
                <Image src={scroll2 >= 450 && scroll2 < 649 ? dotFull : dotEmpty} />
                <Image src={scroll2 >= 650 ? dotFull : dotEmpty} />
            </Flex>

            <Box
                display="flex"
                direction="row"
                align="flex-start"
                bg="danger.100"
                border="1px"
                borderColor="danger.200"
                rounded="4px"
                gap="16px"
                padding="16px"
            >
                <Image alignSelf="flex-start" src={alertRed} />
                <Text type="textSM" css={{color: "$danger600"}}>
                    {t("custom_faqs.how_to_use_middle_journey_alert")}
                </Text>
            </Box>

            <Divider border="1px" borderColor="secondary.500" />

            <Flex direction="column" justify="center" gap="24px">
                <Flex gap="10px">
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        &bull;
                    </Text>
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        {t("custom_faqs.how_to_use_no_validation")}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                overflow="hidden"
                mr="-24px"
                align="center"
            >
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    overflowX="scroll"
                    maxW="calc(100vw)"
                    gap="8px"
                    pr="90px"
                    pl={{sm: "40px", sm2: "70px", sm3: "90px"}}
                    justify="center"
                    sx={{ '::-webkit-scrollbar':{ display:'none' } }}
                    onScroll={handleThirdSlide}
                >
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="62px" src={validatorExit} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_no_validation_step_1")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="62px" src={cardIcon} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_no_validation_step_2")}
                        </Text>
                    </Flex>
                </Grid>
            </Flex>

            <Flex align="center" justify="center">
                <Image src={scroll3 < 200 ? dotFull : dotEmpty} />
                <Image src={scroll3 >= 200 ? dotFull : dotEmpty} />
            </Flex>

            <Box
                display="flex"
                direction="row"
                align="flex-start"
                bg="danger.100"
                border="1px"
                borderColor="danger.200"
                rounded="4px"
                gap="16px"
                padding="16px"
            >
                <Image alignSelf="flex-start" src={alertRed} />
                <Text type="textSM" css={{color: "$danger600"}}>
                    {t("custom_faqs.how_to_use_no_validation_alert")}
                </Text>
            </Box>

            <Divider border="1px" borderColor="secondary.500" />

            <Flex direction="column" justify="center" gap="24px">
                <Flex gap="10px">
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        &bull;
                    </Text>
                    <Text type="textLG" weight="bold" css={{color: "$primary600"}}>
                        {t("custom_faqs.how_to_use_failure_message")}
                    </Text>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                overflow="hidden"
                mr="-24px"
                align="center"
            >
                <Grid
                    templateColumns="repeat(3, 1fr)"
                    overflowX="scroll"
                    maxW="calc(100vw)"
                    gap="8px"
                    pr="90px"
                    pl={{sm: "40px", sm2: "70px", sm3: "90px"}}
                    justify="center"
                    sx={{ '::-webkit-scrollbar':{ display:'none' } }}
                    onScroll={handleFourthSlide}
                >
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="160px" src={validatorError} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_failure_message_step_1")}
                        </Text>
                    </Flex>
                    <Flex justify="center" align="center" padding="8px" gap="8px" height="192px" width="38px">
                        <Image w="20px" src={arrowRight} />
                    </Flex>
                    <Flex direction="column" justify="space-evenly" align="center" bg="white" border="1px solid #DDE1E7" rounded="4px" padding="16px" gap="8px" width="192px" height="192px">
                        <Image w="120px" src={letterIcon} />
                        <Text align="center" type="textMD" weight="semibold">
                            {t("custom_faqs.how_to_use_failure_message_step_2")}
                        </Text>
                    </Flex>
                </Grid>
            </Flex>

            <Flex align="center" justify="center">
                <Image src={scroll4 < 200 ? dotFull : dotEmpty} />
                <Image src={scroll4 >= 200 ? dotFull : dotEmpty} />
            </Flex>

            <Box
                display="flex"
                direction="row"
                align="flex-start"
                bg="neutral.100"
                border="1px"
                borderColor="neutral.200"
                rounded="4px"
                gap="16px"
                padding="16px"
            >
                <Image alignSelf="flex-start" src={infoIcon} />
                <Text type="textSM" css={{color: "$neutral600"}}>
                    {t("custom_faqs.how_to_use_failure_message_information")}
                </Text>
            </Box>
        </Flex>
    );
};
