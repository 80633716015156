import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Text as UbiText,
  Select as UbiSelect,
  Checkbox,
  Column,
  FormItem,
  TextInput,
  setLanguage as setUbiLanguage,
  Row,
  Toggle,
} from "@ubirider/pick-component-library";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from "../../AuthProvider";
import { getUserData, updateLanguage } from "../../api/user";
import { ChangeNameModal } from "../Modals/ChangeNameModal";
import { ChangeEmailModal } from "../Modals/ChangeEmailModal";
import { ChangePassModal } from "../Modals/ChangePassModal";
import { DeleteAccountModal } from "../Modals/DeleteAccountModal";
import { ReauthDelModal } from "../Modals/ReauthDelModal/ReauthDelModal";
import { DeleteContactEmailModal } from "../Modals/DeleteContactEmailModal/DeleteContactEmailModal";
import {
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  linkWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";

import editIcon from "../../assets/edit.svg";
import googleIcon from "../../assets/google.svg";
import facebookIcon from "../../assets/facebook.svg";
import appleIcon from "../../assets/apple.svg";
import { UnlinkModal } from "../Modals/UnlinkModal";

const handleChangeLang = async (language) => {
  await i18next.changeLanguage(language);
  setUbiLanguage(language.toLowerCase());
  await updateLanguage(language);
};

export const Account = () => {
  const { t } = useTranslation();
  const Auth = useContext(AuthContext).user;
  const auth = getAuth();

  console.log(Auth);
  console.log(auth);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fieldChange, setChange] = useState(true);
  const [language, setLanguage] = useState(i18next.language);
  const [checked, setChecked] = useState(false);
  const [methods, setMethods] = useState(null);

  const [googleCheck, setGoogle] = useState(false);
  const [facebookCheck, setFacebook] = useState(false);
  const [appleCheck, setApple] = useState(false);
  const [provider, setProvider] = useState(null);

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues: { language: i18next.language } });

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenName,
    onOpen: onOpenName,
    onClose: onCloseName,
  } = useDisclosure();

  const {
    isOpen: isOpenPassword,
    onOpen: onOpenPassword,
    onClose: onClosePassword,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteEmail,
    onOpen: onOpenDeleteEmail,
    onClose: onCloseDeleteEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenUnlink,
    onOpen: onOpenUnlink,
    onClose: onCloseUnlink,
  } = useDisclosure();

  const {
    isOpen: isOpenPop,
    onOpen: onOpenPop,
    onClose: onClosePop,
  } = useDisclosure();

  const {
    isOpen: isOpenRA,
    onOpen: onOpenRA,
    onClose: onCloseRA,
  } = useDisclosure();

  const languageOptions = [
    { label: t("home.english_language"), value: "EN" },
    { label: t("home.portuguese_language"), value: "PT" },
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const providers = await fetchSignInMethodsForEmail(auth, Auth.email);
      console.log(providers);
      setGoogle(providers?.includes("google.com"));
      setFacebook(providers?.includes("facebook.com"));
      setApple(providers?.includes("apple.com"));
      const user = await getUserData();
      setLoading(false);
      setMethods(providers);
      setData(user);
      if (user.email !== Auth.email) setChecked(true);
      else setChecked(false);
      setChange(false);
    }

    if (fieldChange) fetchData();
  }, [fieldChange, checked]);

  useEffect(() => {
    handleChangeLang(language);
  }, [language]);

  const handleLangPress = (language) => {
    setLanguage(language);
    handleChangeLang(language);
  };

  async function toggleChecked() {
    if (checked) {
      if (data.email === Auth.email) setChecked(false);
      else onOpenDeleteEmail();
    } else {
      setChecked(true);
      onOpenEmail();
    }
  }

  async function toggleGoogle() {
    if (googleCheck) {
      setProvider("Google");
      onOpenUnlink();
    } else {
      const provider = new GoogleAuthProvider();
      linkWithPopup(auth.currentUser, provider)
        .then((result) => {
          setGoogle(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function toggleFacebook() {
    if (facebookCheck) {
      setProvider("Facebook");
      onOpenUnlink();
    } else {
      const provider = new FacebookAuthProvider();
      linkWithPopup(auth.currentUser, provider)
        .then((result) => {
          setFacebook(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function toggleApple() {
    if (appleCheck) {
      setProvider("Apple");
      onOpenUnlink();
    } else {
      const provider = new OAuthProvider("apple.com");
      provider.setCustomParameters({
        locale: navigator.language.substring(
          0,
          navigator.language.indexOf("-")
        ),
      });
      linkWithPopup(auth.currentUser, provider)
        .then((result) => {
          setApple(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Box flex="1">
      <Flex
        bg={{ sm: "", md: "neutral.100", lg: "neutral.100", xl: "neutral.100" }}
        rounded="8px"
        padding={{ sm: "", md: "24px", lg: "24px", xl: "24px" }}
        direction="column"
        flex="1"
        gap="16px"
      >
        <UbiText type="displayXS" weight="bold">
          {t("account.personal_information_title")}
        </UbiText>

        {loading ? (
          <Center minH="387px">
            <Spinner thickness="6px" color="neutral.500" size="xl" />
          </Center>
        ) : (
          <Column css={{ gap: "32px" }}>
            <Column css={{ gap: "16px" }}>
              <FormItem
                label={t("customer_support.registration_email")}
                disabled
              >
                <TextInput placeholder={Auth.email} />
              </FormItem>

              <Row css={{ gap: "16px" }}>
                <Checkbox checked={checked} onChange={toggleChecked} />
                <UbiText type="textSM">
                  {t("customer_support.choose_another_contact_email")}
                </UbiText>
              </Row>
            </Column>

            <Column css={{ gap: "24px" }}>
              {checked && (
                <FormItem required label={t("customer_support.contact_email")}>
                  <Flex flex="1" onClick={onOpenEmail}>
                    <TextInput
                      cursor="pointer"
                      value={data?.email !== Auth.email ? data?.email : null}
                      placeholder={
                        data?.email !== Auth.email
                          ? data?.email
                          : t("authentication.email_address_placeholder")
                      }
                      readOnly
                      rightIconProps={{ src: editIcon }}
                      onRightIconClick={onOpenEmail}
                    />
                  </Flex>
                </FormItem>
              )}
              <Flex
                gap="24px"
                direction="column"
                display={{ sm: "flex", md: "flex", lg: "none", xl: "none" }}
              >
                <FormItem label={t("common.name")}>
                  <Flex flex="1" onClick={onOpenName}>
                    <TextInput
                      cursor="pointer"
                      value={data?.name}
                      placeholder={
                        data?.name ? data?.name : t("account.insert_name")
                      }
                      readOnly
                      rightIconProps={{ src: editIcon }}
                      onRightIconClick={onOpenName}
                    />
                  </Flex>
                </FormItem>
                {methods?.includes("password") && (
                  <FormItem label={t("authentication.your_password")}>
                    <Flex flex="1" onClick={onOpenPassword}>
                      <TextInput
                        value="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;"
                        placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;"
                        readOnly
                        rightIconProps={{ src: editIcon }}
                        onRightIconClick={onOpenPassword}
                      />
                    </Flex>
                  </FormItem>
                )}
              </Flex>
              <Flex
                direction="column"
                gap="16px"
                display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
              >
                <Row css={{ gap: "24px" }}>
                  <Flex flex="5">
                    <FormItem label={t("common.name")}>
                      <Flex flex="1" onClick={onOpenName}>
                        <TextInput
                          cursor="pointer"
                          value={data?.name}
                          placeholder={
                            data?.name ? data?.name : t("account.insert_name")
                          }
                          readOnly
                          rightIconProps={{ src: editIcon }}
                          onRightIconClick={onOpenName}
                        />
                      </Flex>
                    </FormItem>
                  </Flex>
                  {methods?.includes("password") && (
                    <Flex flex="5">
                      <FormItem label={t("authentication.your_password")}>
                        <Flex flex="1" onClick={onOpenPassword}>
                          <TextInput
                            cursor="pointer"
                            value="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;"
                            placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;"
                            readOnly
                            rightIconProps={{ src: editIcon }}
                            onRightIconClick={onOpenPassword}
                          />
                        </Flex>
                      </FormItem>
                    </Flex>
                  )}
                </Row>
              </Flex>

              <Flex direction="column" gap="16px">
                <FormItem label={t("authentication.linked_accounts")}>
                  <Flex direction="column" gap="16px" flex="1">
                    <UbiText type="textSM" css={{ color: "$neutral600" }}>
                      {t("authentication.linked_accounts_hint")}
                    </UbiText>
                    <Flex mt="8px" direction="column" gap="24px" flex="1">
                      <Flex justify="space-between">
                        <Flex gap="24px">
                          <Image w="24px" src={googleIcon} />
                          <UbiText
                            type="textMD"
                            weight="semibold"
                            css={{ color: "$neutral600" }}
                          >
                            Google
                          </UbiText>
                        </Flex>
                        <Toggle checked={googleCheck} onClick={toggleGoogle} />
                      </Flex>
                      <Flex justify="space-between">
                        <Flex gap="24px">
                          <Image w="24px" src={facebookIcon} />
                          <UbiText
                            type="textMD"
                            weight="semibold"
                            css={{ color: "$neutral600" }}
                          >
                            Facebook
                          </UbiText>
                        </Flex>
                        <Toggle
                          checked={facebookCheck}
                          onClick={toggleFacebook}
                        />
                      </Flex>
                      <Flex justify="space-between">
                        <Flex gap="24px">
                          <Image w="24px" src={appleIcon} />
                          <UbiText
                            type="textMD"
                            weight="semibold"
                            css={{ color: "$neutral600" }}
                          >
                            Apple
                          </UbiText>
                        </Flex>
                        <Toggle checked={appleCheck} onClick={toggleApple} />
                      </Flex>
                    </Flex>
                  </Flex>
                </FormItem>
              </Flex>

              <Flex gap="24px">
                <Flex flex="5">
                  <Controller
                    name="language"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormItem
                        label={t("home.language")}
                        error={errors.language}
                      >
                        <UbiSelect
                          value={value}
                          options={languageOptions}
                          onChange={(v) => {
                            onChange(v);
                            handleLangPress(v);
                          }}
                        />
                      </FormItem>
                    )}
                  />
                </Flex>
                <Box
                  display={{ sm: "none", md: "none", lg: "block", xl: "block" }}
                  flex="5"
                />
              </Flex>

              <Flex>
                <UbiButton
                  onClick={onOpenPop}
                  style="ghost"
                  type="danger"
                  label={t("account.delete_account")}
                  css={{ padding: "16px 4px" }}
                />
              </Flex>
            </Column>
          </Column>
        )}
        <ChangePassModal
          onOpen={onOpenPassword}
          onClose={onClosePassword}
          isOpen={isOpenPassword}
        />
        <ChangeNameModal
          onOpen={onOpenName}
          onClose={onCloseName}
          isOpen={isOpenName}
          setNameChange={setChange}
          name={data.name}
        />
        <ChangeEmailModal
          onOpen={onOpenEmail}
          onClose={onCloseEmail}
          isOpen={isOpenEmail}
          setEmailChange={setChange}
          email={data.email}
        />
        <DeleteContactEmailModal
          onOpen={onOpenDeleteEmail}
          onClose={onCloseDeleteEmail}
          isOpen={isOpenDeleteEmail}
          fieldChange={setChange}
          email={data.email}
        />
        <UnlinkModal
          onOpen={isOpenUnlink}
          onClose={onCloseUnlink}
          isOpen={isOpenUnlink}
          provider={provider}
          setProvider={
            provider === "Google"
              ? setGoogle
              : provider === "Facebook"
              ? setFacebook
              : provider === "Apple"
              ? setApple
              : null
          }
        />
        <DeleteAccountModal
          onOpen={isOpenPop}
          onClose={onClosePop}
          isOpen={isOpenPop}
          onOpenRA={onOpenRA}
        />
        <ReauthDelModal
          onOpen={onOpenRA}
          onClose={onCloseRA}
          isOpen={isOpenRA}
        />
      </Flex>
    </Box>
  );
};
