import { Flex, Image } from "@chakra-ui/react";
import { MenuButton } from "./MenuButton";

import fertagusLogo from "../../../../assets/fertagus.svg";
import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import { AuthContext } from "../../../../AuthProvider";

export function Header() {
  const navigate = useNavigate();
  const Auth = useContext(AuthContext);

  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    if (Auth.user) {
      setShowButton(true);
    }
    else {
      setShowButton(false);
    }
  },[Auth.user])

  return (
      <Flex
          as="header"
          position="fixed"
          width="100%"
          height={{sm: "67px", md: "67px", lg: "72px", xl: "72px"}}
          css={"box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)"}
          alignItems="center"
          justify="space-between"
          bg="white"
          zIndex={{sm: "1500", md: "1500", lg: "500", xl: "500"}}
      >
        <Flex
            as="button"
            onClick={()=>{navigate("/home")}}
            pt={{sm: "16px", md: "12px", lg: "20px", xl: "20px"}}
            pb={{sm: "16px", md: "12px", lg: "26px", xl: "26px"}}
            pl={{sm: "24px", md: "36px", lg: "130px", xl: "130px"}}
        >
          <Image src={fertagusLogo} alt="logo" height={{sm: "40px", md: "40px", lg: "42px", xl: "42px"}}></Image>
        </Flex>
        {showButton && <Flex display={{sm: "flex", md: "flex", lg: "none", xl: "none"}} mr="24px">
          <MenuButton/>
        </Flex>}
      </Flex>
  );
}
