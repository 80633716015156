import axios from 'axios'
import { getBFFUrl, getToken } from './requests'

export const getTokenizedURL = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/tokenize`, {
    headers: {
      'x-access-token': token,
    },
    params: {
      redirect: 'window.location.href',
    },
  })

  return res.data.data.gtwUrl
}

export const getCards = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/cards`, {
    headers: {
      'x-access-token': token,
    },
  })

  return res.data.data.cards
}

export const deleteCard = async cardToken => {
  const token = await getToken()

  await axios.delete(`${getBFFUrl()}/cards?cardToken=${cardToken}`, {
    headers: {
      'x-access-token': token,
    },
  })

  //console.log(res)
  return true
}

export const updateSettledCards = async cards => {
  const token = await getToken()

  const res = await axios.post(
    `${getBFFUrl()}/status`,
    { cards },
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  console.log(res.data)
  return res.data
}
