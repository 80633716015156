import {
    Box,
    Flex,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    useToast,
} from "@chakra-ui/react";
import {Button as UbiButton} from "@ubirider/pick-component-library";
import React, {useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { IconWithinCircle } from "../../Common/IconWithinCircle";

import refresh from "../../../assets/refresh.svg";
import checkWhiteIcon from "../../../assets/check_circle_white.svg";
import closeWhiteIcon from "../../../assets/cross_white_24.svg";
import closeButton from "../../../assets/cross.svg";

export const VerifyAccountModal = (props) => {
    const { t } = useTranslation();
    const toast = useToast();
    const toastIdRef = useRef();


    const [loading, setLoading] = useState(false);

    function resendEmail() {
        console.log(props.email)
        setLoading(true)
        //missing request to send email
        setLoading(false)
        toastIdRef.current = toast({
            position: 'top',
            duration: 5000,
            isClosable: true,
            render: () => (
                <Box alignItems="center" color='white' bg='#01AE70' border="1px solid #067F5B" boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)" borderRadius="4px">
                    <Flex justifyContent="space-between" gap="16px" padding="16px">
                        <Image src={checkWhiteIcon} />
                        <Text fontSize="14px" fontWeight="600">{t("authentication.email_sent_toast")}</Text>
                        <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
                    </Flex>
                </Box>
            ),
        })
    }

    function close() {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
        }
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            size={{sm: "xs", md: "2xl", lg: "2xl", xl: "2xl"}}
            closeOnOverlayClick={false}
            autoFocus={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent padding={{sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px"}}>
                <Flex direction="row" justifyContent="flex-end" alignItems="center">
                    <Flex as="button" onClick={props.onClose}>
                        <Image src={closeButton} />
                    </Flex>
                </Flex>
                <Flex direction="column" gap="48px" align="center">
                    <Flex direction="column" gap="16px" align="center">
                        <IconWithinCircle color="success.600" icon={refresh} />
                        <Flex direction="column" gap="8px" align="center">
                            <Text
                                fontWeight="700"
                                fontSize={{sm: "20px", md: "20px", lg: "24px", xl: "24px"}}
                                color="neutral.800"
                                align="center"
                            >
                                {t("authentication.youre_almost_there")}
                            </Text>
                            <Text
                                fontWeight="600"
                                fontSize={{sm: "14px", md: "14px", lg: "16px", xl: "16px"}}
                                color="neutral.600"
                                align="center"
                                width={{md: "380px", lg: "380px", xl: "380px"}}
                            >
                                {t("authentication.finish_registration_by_clicking_on_link")}
                            </Text>
                        </Flex>
                    </Flex>

                    <UbiButton
                        onClick={resendEmail}
                        loading={loading}
                        style="solid"
                        type="success"
                        label={t("authentication.resend_email")}
                    />
                </Flex>
            </ModalContent>
        </Modal>
    );
};
