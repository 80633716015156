import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, Image,
    Input, InputGroup, InputRightElement,
    Text, useToast,
} from "@chakra-ui/react";
import { Button as UbiButton } from "@ubirider/pick-component-library";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../AuthProvider";
import firebase from "../../Firebase";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import closedEye from "../../assets/closed_eye.svg";
import eye from "../../assets/eye.svg";
import checkIcon from "../../assets/check_green.svg";
import closeIcon from "../../assets/cross_red.svg";
import checkWhiteIcon from "../../assets/check_circle_white.svg";
import closeWhiteIcon from "../../assets/cross_white_24.svg";

export const NewPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const handleClick = () => setShow(!show);

    const password = useRef({});
    password.current = watch("password", "");

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pwd, updatePwd] = useState("");
    const [firstParam, setFirstParam] = useState(false);
    const [secondParam, setSecondParam] = useState(false);
    const [thirdParam, setThirdParam] = useState(false);
    const [fourthParam, setFourthParam] = useState(false);
    const [fifthParam, setFifthParam] = useState(false);

    const checkPassword = (e) => {
        updatePwd(e)
        //Check if password has min 8 chars
        if (e.length >= 8) {
            setFirstParam(true)
        }
        else {
            setFirstParam(false)
        }
        //Check if password contains one uppercase
        if (/[A-Z]/.test(e)) {
            setSecondParam(true)
        }
        else {
            setSecondParam(false)
        }
        //Check if password contains one lowercase
        if (/[a-z]/.test(e)) {
            setThirdParam(true)
        }
        else {
            setThirdParam(false)
        }
        //Check if password contains one number
        if (/\d/.test(e)) {
            setFourthParam(true)
        }
        else {
            setFourthParam(false)
        }
    }

    const checkPasswordAgain = (e) => {
        //Repeat password
        if (e === pwd) {
            setFifthParam(true)
        }
        else {
            setFifthParam(false)
        }
    }

    const toast = useToast();
    const toastIdRef = useRef();

    function close() {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
        }
    }

    const onSubmit = async (values) => {
        setLoading(true);
        try {
            toastIdRef.current = toast({
                position: 'top',
                duration: 5000,
                isClosable: true,
                render: () => (
                    <Box alignItems="center" color='white' bg='#01AE70' border="1px solid #067F5B" boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)" borderRadius="4px">
                        <Flex justifyContent="space-between" gap="16px" padding="16px">
                            <Image src={checkWhiteIcon} />
                            <Text fontSize="14px" fontWeight="600">{t("authentication.password_changed")}</Text>
                            <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
                        </Flex>
                    </Box>
                ),
            })
            navigate('/');
        } catch (error) {
            console.log(error.message);
            setLoading(false);
        }
    };

    return (
      <Box>
          <Flex
              direction="column"
              bg="white"
              rounded="8px"
              css={"box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)"}
              flex="1"
          >
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Flex direction="column" gap="24px" padding="36px">
                      <Text fontSize="24px" fontWeight="700" color="neutral.800">
                          {t("authentication.set_new_password")}
                      </Text>
                      <Flex direction="column" gap="8px">
                          <Text fontSize="14px" fontWeight="600" color="neutral.800">
                              {t("authentication.your_password")}
                          </Text>
                          <FormControl variant="floating">
                              <InputGroup>
                                  <Input
                                      placeholder=" "
                                      type={show ? "text" : "password"}
                                      name="password"
                                      id="password"
                                      {...register("password", {
                                          required: t("authentication.required_field"),
                                          minLength: {
                                              value: 8,
                                              message: "Minimum length should be 8",
                                          },
                                      })}
                                      onChange={e => checkPassword(e.target.value)}
                                  />
                                  <InputRightElement width="4rem" pt="25px">
                                      <Flex as="button" type="button" onClick={handleClick}>
                                          {show ? <Image src={closedEye} /> : <Image src={eye} />}
                                      </Flex>
                                  </InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>
                                  {errors.password && errors.password.message}
                              </FormErrorMessage>
                              <FormLabel>{t("authentication.create_password_placeholder")}</FormLabel>
                          </FormControl>
                          <FormControl variant="floating" isInvalid={errors.confirmPassword}>
                              <InputGroup>
                                  <Input
                                      placeholder=" "
                                      type={show ? "text" : "password"}
                                      name="confirmPassword"
                                      id="confirmPassword"
                                      {...register("confirmPassword", {
                                          required: t("authentication.required_field"),
                                          validate: (value) =>
                                              value === password.current ||
                                              t("authentication.password_doesnt_match"),
                                      })}
                                      onChange={(e) => {checkPasswordAgain(e.target.value)}}
                                  />
                                  <InputRightElement width="4rem" pt="25px">
                                      <Flex as="button" type="button" onClick={handleClick}>
                                          {show ? <Image src={closedEye} /> : <Image src={eye} />}
                                      </Flex>
                                  </InputRightElement>
                              </InputGroup>
                              <FormErrorMessage>
                                  {errors.confirmPassword && errors.confirmPassword.message}
                              </FormErrorMessage>
                              <FormLabel>{t("confirm_password_placeholder")}</FormLabel>
                          </FormControl>
                      </Flex>
                      <Flex direction="column" gap="8px">
                          <Text fontSize="14px" fontWeight="600" color="neutral.800">
                              {t("authentication.password_requirements")}
                          </Text>
                          <Flex direction="column" gap="4px">
                              <Flex gap="8px">
                                  <Image src={firstParam ? checkIcon : closeIcon }/>
                                  <Text fontSize="14px" fontWeight="400" color="neutral.600" lineHeight="20px">
                                      {t("authentication.at_least_8_characters")}
                                  </Text>
                              </Flex>
                              <Flex gap="8px">
                                  <Image src={secondParam ? checkIcon : closeIcon }/>
                                  <Text fontSize="14px" fontWeight="400" color="neutral.600" lineHeight="20px">
                                      {t("authentication.one_uppercase")}
                                  </Text>
                              </Flex>
                              <Flex gap="8px">
                                  <Image src={thirdParam ? checkIcon : closeIcon }/>
                                  <Text fontSize="14px" fontWeight="400" color="neutral.600" lineHeight="20px">
                                      {t("authentication.one_lowercase")}
                                  </Text>
                              </Flex>
                              <Flex gap="8px">
                                  <Image src={fourthParam ? checkIcon : closeIcon }/>
                                  <Text fontSize="14px" fontWeight="400" color="neutral.600" lineHeight="20px">
                                      {t("authentication.one_number")}
                                  </Text>
                              </Flex>
                              <Flex gap="8px">
                                  <Image src={fifthParam ? checkIcon : closeIcon }/>
                                  <Text fontSize="14px" fontWeight="400" color="neutral.600" lineHeight="20px">
                                      {t("authentication.type_password_to_confirm")}
                                  </Text>
                              </Flex>
                          </Flex>
                      </Flex>
                      <Flex direction="column" gap="8px">
                          <UbiButton
                              submit
                              loading={loading}
                              style="solid"
                              type="primary"
                              label={t("authentication.reset_password")}
                          />
                      </Flex>
                  </Flex>
              </form>
          </Flex>
      </Box>
    );
};