import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Image,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Column,
  FormItem,
  TextInput,
} from "@ubirider/pick-component-library";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import closeButton from "../../../assets/cross.svg";
import checkWhiteIcon from "../../../assets/check_circle_white.svg";
import closeWhiteIcon from "../../../assets/cross_white_24.svg";
import arrowBackIcon from "../../../assets/arrow_left.svg";

export function NewCardNumberModal(props) {
  const { t } = useTranslation();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const toast = useToast();
  const toastIdRef = useRef();

  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      card: null,
    },
  });

  const card = watch("card");

  useEffect(() => {
    reset({
      card: null,
    });
    setLoading(false);
  }, [props.isOpen, props.onClose, reset]);

  const onSubmit = async (values) => {
    props.onSave(values.card);
    if (values.card !== null) handleChange();
  };

  const handleChange = () => {
    toastIdRef.current = toast({
      position: "top",
      duration: 5000,
      isClosable: true,
      render: () => (
        <Box
          alignItems="center"
          color="white"
          bg="#01AE70"
          border="1px solid #067F5B"
          boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)"
          borderRadius="4px"
        >
          <Flex justifyContent="space-between" gap="16px" padding="16px">
            <Image src={checkWhiteIcon} />
            <Text fontSize="14px" fontWeight="600">
              {t("customer_support.card_number_added_toast")}
            </Text>
            <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
          </Flex>
        </Box>
      ),
    });
    props.onClose();
    setLoading(false);
  };

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: "full", md: "full", lg: "xl", xl: "xl" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px" }}
      >
        <Flex
          direction="column"
          gap="24px"
          mt={{ sm: "67px", md: "67px", lg: "0px", xl: "0px" }}
        >
          {windowSize.current[0] < 960 && (
            <Flex alignItems="flex-start">
              <UbiButton
                onClick={props.onClose}
                style="ghost"
                type="neutralLight"
                label={t("authentication.back")}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: "0" }}
              />
            </Flex>
          )}

          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontWeight="700" fontSize="24px" flex="1">
              {t("customer_support.add_new_card_number")}
            </Text>
            <Flex
              display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
              as="button"
              onClick={props.onClose}
            >
              <Image src={closeButton} />
            </Flex>
          </Flex>

          <Text fontSize="16px">{t("customer_support.security_reasons")}</Text>

          <Flex
            direction="column"
            gap="24px"
            width="100%"
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="card"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormItem label={t("common.card_number")} error={errors.card}>
                  <TextInput
                    placeholder={t("customer_support.enter_card_number")}
                    value={value}
                    onChange={(e) => {
                      if (!(e?.length > 19)) {
                        if (!isNaN(e?.slice(-1)) || e?.slice(-1) === "*") {
                          let inputValue = e;
                          if (inputValue.length > 8 && inputValue.length < 17) {
                            if (
                              inputValue.charAt(inputValue.length - 2) === " "
                            ) {
                              inputValue =
                                inputValue.substring(0, inputValue.length - 3) +
                                "* " +
                                inputValue.charAt(inputValue.length - 1);
                            } else {
                              inputValue =
                                inputValue.substring(0, inputValue.length - 2) +
                                "*" +
                                inputValue.charAt(inputValue.length - 1);
                            }
                          }
                          if (
                            value?.length > inputValue.length &&
                            value?.slice(-1) === " "
                          ) {
                            inputValue = inputValue.slice(0, -1);
                          } else if (
                            inputValue.length === 4 ||
                            inputValue.length === 9 ||
                            inputValue.length === 14
                          ) {
                            inputValue += " ";
                          }
                          onChange(inputValue);
                        }
                        if (e === null) {
                          onChange(e);
                        }
                      }
                    }}
                  />
                </FormItem>
              )}
            />

            <UbiButton
              submit
              loading={loading}
              disabled={card?.length !== 19}
              style="solid"
              type="secondary"
              label={t("common.save")}
              css={{ alignSelf: "stretch" }}
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
