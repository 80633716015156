import axios from 'axios'
import { getBFFUrl, getToken } from './requests'

export const sendCustomerSupportEmail = async (data, uid, checked) => {
  const token = await getToken()

  const params = {
    template: 'customer_support',
    app: 'fertagus',
    recipient: 'info@fertagus.pt',
    data: {
      type: 'support',
      name: data.name,
      email: data.email,
      phone: data.phone,
      card: data.card,
      message: data.message,
      saveData: checked === 'yes',
    },
  }

  const res = await axios.post(`${getBFFUrl()}/email`, params, {
    headers: {
      'x-access-token': token,
    },
  })

  //console.log(res.data)
  return res.data
}
