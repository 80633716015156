import { Text, Flex, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { useState } from "react";

export function RouteButton(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => { setIsHover(true) };
    const handleMouseLeave = () => { setIsHover(false) };

    return (
        <Link to={props.route} style={{width: "100%"}} >
            <Flex
                gap="24px"
                width="100%"
                _hover={{ bg: "#E0EAFF", fontWeight: "700" }}
                padding={{sm: "16px 8px", md: "16px 8px", lg: "16px 8px 16px 16px", xl: "16px 8px 16px 16px"}}
                rounded="4px"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                as="button"
                onClick={() => { if (props?.onClose) props.onClose() }}
            >
                <Image w="24px" src={props.icon} />
                <Text
                    variant="link"
                    color="neutral.800"
                    fontWeight={location.pathname === props.route ? "700" : isHover ? "600" : "400"}
                    fontSize="18px"
                    _hover={{}}
                    disabled={props.disabled}
                >
                    {t(`${props.label}`)}
                </Text>
            </Flex>
        </Link>
    );
}
