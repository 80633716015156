import React from "react";
import { Center, Grid, Image, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import verticalValidator from "../../../../assets/vertical_validator.svg";
import gateValidator from "../../../../assets/gate_validator.svg";
import check from "../../../../assets/check_circle_green.svg";
import cross from "../../../../assets/cross_circle_white.svg";

const stations = [
  {
    name: "Roma-Areeiro",
    validator: "vertical",
  },
  {
    name: "Entrecampos",
    validator: "vertical",
  },
  {
    name: "Sete Rios",
    validator: "vertical",
  },
  {
    name: "Campolide",
    validator: "vertical",
  },
  {
    name: "Pragal",
    validator: "gate",
  },
  {
    name: "Corroios",
    validator: "gate",
  },
  {
    name: "Foros de Amora",
    validator: "gate",
  },
  {
    name: "Fogueteiro",
    validator: "gate",
  },
  {
    name: "Coina",
    validator: "gate",
  },
  {
    name: "Penalva",
    validator: "gate",
  },
  {
    name: "Pinhal Novo",
    validator: "vertical",
  },
  {
    name: "Venda do Alcaide",
    validator: "vertical",
  },
  {
    name: "Palmela",
    validator: "vertical",
  },
  {
    name: "Setúbal",
    validator: "vertical",
  },
];

export const ValidatorTable = (props) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateColumns="repeat(3, minmax(0, 1fr))"
      gridColumnGap="16px"
      py="16px"
    >
      <Center>
        <Text fontWeight="bold" fontSize="16px">
          {t("custom_faqs.types_of_validators_train_stations")}
        </Text>
      </Center>
      <VStack p="4px" alignItems="center" justifyContent="center">
        <Image src={verticalValidator} height="48px" />
        <Text fontWeight="semibold" fontSize="13px" align="center">
          {t("custom_faqs.types_of_validators_vertical_validator")}
        </Text>
      </VStack>
      <VStack p="4px" alignItems="center" justifyContent="center">
        <Image src={gateValidator} height="48px" />
        <Text fontWeight="semibold" fontSize="13px" align="center">
          {t("custom_faqs.types_of_validators_validator_with_gate")}
        </Text>
      </VStack>
      {stations.map((station, index) => (
        <React.Fragment key={index}>
          <Center
            height="40px"
            borderBottom={index !== stations.length - 1 ? "1px solid" : null}
            borderColor="neutral.300"
          >
            <Text justify="center" align="center" fontSize="13px">{station.name}</Text>
          </Center>
          <Center
            height="40px"
            borderBottom={index !== stations.length - 1 ? "1px solid" : null}
            borderColor="neutral.300"
          >
            <Image
              src={station.validator === "vertical" ? check : cross}
              height="24px"
            />
          </Center>
          <Center
            height="40px"
            borderBottom={index !== stations.length - 1 ? "1px solid" : null}
            borderColor="neutral.300"
          >
            <Image
              src={station.validator === "gate" ? check : cross}
              height="24px"
            />
          </Center>
        </React.Fragment>
      ))}
    </Grid>
  );
};
