import { extendTheme } from "@chakra-ui/react";
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-10px) translate(2px)",
};

const breakpoints = {
  sm: "160px",
  sm2: "375px",
  sm3: "423px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  xxl: "1472px",
};

const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      /*
      'body': {
        minWidth: '1296px',
      },
      '#root': {
        minWidth: '1296px',
      }*/
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            input: {
              pt: "17px",
              placeholder: " ",
              bg: "neutral.50",
              border: "1px",
              borderColor: "neutral.400",
              h: "64px",
              rounded: "4px",
              _hover: { borderColor: "secondary.500" },
            },
            textarea: {
              pt: "17px",
              placeholder: " ",
              bg: "neutral.50",
              border: "1px",
              borderColor: "neutral.400",
              h: "64px",
              rounded: "4px",
              _hover: { borderColor: "secondary.500" },
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              color: "neutral.400",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 5,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    neutral: {
      25: "#FCFCFD",
      50: "#F9F9FB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      800: "#1D2939",
      900: "#171923",
    },
    secondary: {
      25: "#F5F8FF",
      50: "#EEF4FF",
      100: "#E0EAFF",
      200: "#C7D7FE",
      500: "#6172F3",
      600: "#444CE7",
      800: "#1D2939",
    },
    success: {
      600: "#067F5B",
    },
    warning: {
      50: "#FEF7D4",
      100: "#FEECAA",
      200: "#FDE180",
      400: "#FBC02D",
      500: "#C6901E",
      600: "#91600E",
    },
    danger: {
      100: "#FEE4E2",
      200: "#FECDCA",
      600: "#D23636",
      700: "#B42318",
      800: "#912018",
    },
  },
});

export default theme;
