import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Text,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import {
  Button,
  Text as UbiText,
  Column,
} from "@ubirider/pick-component-library";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IconWithinCircle } from "../../Common/IconWithinCircle";

import redirectIcon from "../../../assets/shield_white.svg";

export function TermsConditionsModal(props) {
  const { t } = useTranslation();

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      size={{ sm: "full", lg: "" }}
      trapFocus={true}
      closeOnOverlayClick={false}
      autoFocus={true}
    >
      <ModalOverlay />
      <ModalContent
        maxW={{ lg: "700px" }}
        maxH={{ lg: "calc(100vh - 64px)" }}
        mt={{ lg: "32px" }}
        mb={{ lg: "32px" }}
        gap="24px"
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "48px", xl: "48px" }}
      >
        <Flex
          direction="column"
          gap="16px"
          mt={{ sm: "68px", md: "68px", lg: "0px", xl: "0px" }}
        >
          <Flex
            direction="column"
            align="center"
            justifyContent="center"
            gap="16px"
          >
            <IconWithinCircle color="secondary.600" icon={redirectIcon} />
            <Text
              fontWeight="700"
              fontSize={{ sm: "20px", md: "20px", lg: "24px", xl: "24px" }}
              color="neutral.800"
              align="center"
            >
              {t("authentication.use_of_personal_data_modal")}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="column" gap="16px">
          <UbiText type="textSM" css={{ color: "$neutral600" }}>
            {t("authentication.use_of_personal_data_modal_description_1")}
          </UbiText>
          <Flex
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <UnorderedList>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_1"
                  )}
                </UbiText>
              </ListItem>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_2"
                  )}
                </UbiText>
              </ListItem>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_3"
                  )}
                </UbiText>
              </ListItem>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_4"
                  )}
                </UbiText>
              </ListItem>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_5"
                  )}
                </UbiText>
              </ListItem>
              <ListItem>
                <UbiText type="textSM" css={{ color: "$neutral600" }}>
                  {t(
                    "authentication.use_of_personal_data_modal_description_list_6"
                  )}
                </UbiText>
              </ListItem>
            </UnorderedList>
          </Flex>
          <UbiText type="textSM" css={{ color: "$neutral600" }}>
            {t("authentication.use_of_personal_data_modal_description_2")}
          </UbiText>
          <UbiText type="textSM" css={{ color: "$neutral600" }}>
            {t("authentication.use_of_personal_data_modal_description_3")}
          </UbiText>
          <UbiText type="textSM" css={{ color: "$neutral600" }}>
            {t("authentication.use_of_personal_data_modal_description_4")}
          </UbiText>
        </Flex>

        <Column
          w="100%"
          css={{ justifyContent: "center", alignItems: "center", gap: "24px" }}
        >
          <Flex w="100%" justify="center" display={{ sm: "none", lg: "flex" }}>
            <Button
              ref={initialRef}
              loading={props.loading}
              onClick={props.handleAgree}
              style="solid"
              type="primary"
              label={t("authentication.i_agree")}
              css={{ width: "173px" }}
            />
          </Flex>
          <Flex w="100%" display={{ sm: "flex", lg: "none" }}>
            <Button
              ref={initialRef}
              loading={props.loading}
              onClick={props.handleAgree}
              style="solid"
              type="primary"
              label={t("authentication.i_agree")}
              css={{ width: "100%" }}
            />
          </Flex>

          <Flex
            gap={{ sm: "", md: "", lg: "16px", xl: "16px" }}
            alignItems="center"
            justifyContent="center"
            width="100%"
            direction={{ sm: "column", md: "column", lg: "row", xl: "row" }}
          >
            <Button
              onClick={() =>
                window.open("/faqs", "_blank", "noopener,noreferrer")
              }
              style="ghost"
              type="neutralLight"
              size="small"
              label={t("faqs.faqs")}
              css={{ textDecoration: "underline" }}
            />
            <Button
              onClick={() =>
                window.open(
                  "https://www.fertagus.pt/pt/Privacidade",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              style="ghost"
              type="neutralLight"
              size="small"
              label={t("authentication.privacy_policy")}
              css={{ textDecoration: "underline" }}
            />
          </Flex>
        </Column>
      </ModalContent>
    </Modal>
  );
}
