import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Image,
  Text,
  Button,
  Center,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { Button as UbiButton, Column } from "@ubirider/pick-component-library";
import { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import closeButton from "../../../assets/cross.svg";
import arrowBackIcon from "../../../assets/arrow_left.svg";
import appleIcon from "../../../assets/apple.svg";
import googleIcon from "../../../assets/google.svg";
import facebookIcon from "../../../assets/facebook.svg";
import emailIcon from "../../../assets/email.svg";
import alertIcon from "../../../assets/alert_yellow.svg";

export function LinkCredentialLoginModal(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const [userChoice, setChoice] = useState(false);

  async function useNewSignIn() {
    setChoice(true);
    const auth = getAuth();
    const provider =
      props.provider === "Google"
        ? new GoogleAuthProvider()
        : props.provider === "Apple"
        ? new OAuthProvider("apple.com")
        : null;
    if (props.provider === "email and password") {
      // open modal to ask for password
      /*
            auth.signInWithEmailAndPassword(email, password).then(function(result) {
                // Step 4a.
                return result.user.linkWithCredential(pendingCred);
            }).then(function() {
                // Facebook account successfully linked to the existing Firebase user.
                goToApp();
            });*/
    } else {
      setTimeout(async () => {
        await signInWithPopup(auth, provider)
          .then(async (result) => {
            console.log(result);
            await linkWithCredential(result.user, props.credential)
              .then((result) => {
                navigate("/home");
                console.log(result);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
            setChoice(false);
          });
      }, 2500);
    }
  }

  async function useOldSignIn() {
    const auth = getAuth();
    const provider =
      props.provider === "Google"
        ? new GoogleAuthProvider()
        : props.provider === "Apple"
        ? new OAuthProvider("apple.com")
        : null;
    if (props.provider === "email and password") {
      // open modal to ask for password
      /*
            auth.signInWithEmailAndPassword(email, password).then(function(result) {
                // Step 4a.
                return result.user.linkWithCredential(pendingCred);
            }).then(function() {
                // Facebook account successfully linked to the existing Firebase user.
                goToApp();
            });*/
    } else {
      await signInWithPopup(auth, provider)
        .then(async (result) => {
          console.log(result);
          navigate("/home");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function useAppleSignIn() {
    const auth = getAuth();
    const provider = new OAuthProvider("apple.com");

    await signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function useFacebookSignIn() {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();

    await signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function useGoogleSignIn() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      trapFocus={false}
      closeOnOverlayClick={false}
      autoFocus={false}
      size={{ sm: "full", md: "full", lg: "xl", xl: "xl" }}
      isCentered={windowSize.current[0] >= 960}
    >
      <ModalOverlay />
      <ModalContent
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "36px", xl: "36px" }}
      >
        <Flex
          direction="column"
          gap="24px"
          mt={{ sm: "67px", md: "67px", lg: "0px", xl: "0px" }}
        >
          {windowSize.current[0] < 960 && (
            <Flex alignItems="flex-start">
              <UbiButton
                onClick={props.onClose}
                style="ghost"
                type="neutralLight"
                label={t("authentication.back")}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: "0" }}
              />
            </Flex>
          )}

          <Flex justifyContent="space-between" alignItems="center">
            <Flex justify="center" align="center" flex="1">
              {props.attemptedProvider === "Apple" && (
                <Flex direction="column" gap="8px">
                  <Flex gap="16px">
                    <Image src={appleIcon} />
                    <Text
                      textAlign="center"
                      fontWeight="700"
                      fontSize="24px"
                      flex="1"
                    >
                      Login
                    </Text>
                  </Flex>
                  {!userChoice && (
                    <Text fontSize="16px">
                      <Trans i18nKey="authentication.selected_apple" />
                    </Text>
                  )}
                </Flex>
              )}
              {props.attemptedProvider === "Facebook" && (
                <Flex
                  align="center"
                  justify="center"
                  direction="column"
                  gap="8px"
                >
                  <Flex gap="16px" align="center" justify="center">
                    <Image src={facebookIcon} />
                    <Text textAlign="center" fontWeight="700" fontSize="24px">
                      Login
                    </Text>
                  </Flex>
                  {!userChoice && (
                    <Text fontSize="16px">
                      <Trans i18nKey="authentication.selected_facebook" />
                    </Text>
                  )}
                </Flex>
              )}
              {props.attemptedProvider === "Google" && (
                <Flex direction="column" gap="8px">
                  <Flex align="center" justify="center" gap="16px">
                    <Image src={googleIcon} />
                    <Text
                      textAlign="center"
                      fontWeight="700"
                      fontSize="24px"
                      flex="1"
                    >
                      {t("common.login")}
                    </Text>
                  </Flex>
                  {!userChoice && (
                    <Text fontSize="16px">
                      <Trans i18nKey="authentication.selected_google" />
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
            <Flex
              alignSelf="flex-start"
              display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
              as="button"
              onClick={props.onClose}
            >
              <Image src={closeButton} />
            </Flex>
          </Flex>

          {!userChoice && (
            <Column css={{ gap: "24px", width: "100%" }}>
              <Flex
                bg="warning.100"
                border="1px solid #FDE180"
                align="center"
                gap="16px"
                padding="8px 16px"
                borderRadius="4px"
              >
                <Image src={alertIcon} />
                {props.provider === "Apple" && (
                  <Text fontSize="14px" color="warning.600">
                    <Trans i18nKey="authentication.usually_use_apple" />
                  </Text>
                )}
                {props.provider === "Facebook" && (
                  <Text fontSize="14px" color="warning.600">
                    <Trans i18nKey="authentication.usually_use_facebook" />
                  </Text>
                )}
                {props.provider === "Google" && (
                  <Text fontSize="14px" color="warning.600">
                    <Trans i18nKey="authentication.usually_use_google" />
                  </Text>
                )}
              </Flex>
              <Flex gap="24px" justify="space-between">
                {props.attemptedProvider === "Apple" && (
                  <Button
                    w="48%"
                    onClick={useNewSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={appleIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.continue_apple")}
                    </Text>
                  </Button>
                )}
                {props.attemptedProvider === "Facebook" && (
                  <Button
                    w="48%"
                    onClick={useNewSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={facebookIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.continue_facebook")}
                    </Text>
                  </Button>
                )}
                {props.attemptedProvider === "Google" && (
                  <Button
                    w="48%"
                    onClick={useNewSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={googleIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.continue_google")}
                    </Text>
                  </Button>
                )}
                {props.provider === "Apple" && (
                  <Button
                    w="48%"
                    onClick={useOldSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={appleIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.use_apple")}
                    </Text>
                  </Button>
                )}
                {props.provider === "Facebook" && (
                  <Button
                    w="48%"
                    onClick={useOldSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={facebookIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.use_facebook")}
                    </Text>
                  </Button>
                )}
                {props.provider === "Google" && (
                  <Button
                    w="48%"
                    onClick={useOldSignIn}
                    gap="12px"
                    border="2px solid #D0D5DD"
                    boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)"
                    bg="white"
                  >
                    <Image src={googleIcon} />
                    <Text color="gray.700" fontSize="16px" fontWeight="500">
                      {t("authentication.use_google")}
                    </Text>
                  </Button>
                )}
              </Flex>
              <Flex gap="16px" justify="center" align="center">
                <Text fontSize="14px" color="neutral.600">
                  {t("authentication.add_other_platforms")}
                </Text>
                {props.attemptedProvider === "Google" &&
                  props.provider === "Facebook" && (
                    <>
                      <UbiButton
                        onClick={useAppleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: appleIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Google" &&
                  props.provider === "Apple" && (
                    <>
                      <UbiButton
                        onClick={useFacebookSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: facebookIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Google" &&
                  props.provider === "email and password" && (
                    <>
                      <UbiButton
                        onClick={useAppleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: appleIcon }}
                      />
                      <UbiButton
                        onClick={useFacebookSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: facebookIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Facebook" &&
                  props.provider === "Google" && (
                    <>
                      <UbiButton
                        onClick={useAppleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: appleIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Facebook" &&
                  props.provider === "Apple" && (
                    <>
                      <UbiButton
                        onClick={useGoogleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: googleIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Facebook" &&
                  props.provider === "email and password" && (
                    <>
                      <UbiButton
                        onClick={useAppleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: appleIcon }}
                      />
                      <UbiButton
                        onClick={useGoogleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: googleIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Apple" &&
                  props.provider === "Google" && (
                    <>
                      <UbiButton
                        onClick={useFacebookSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: facebookIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Apple" &&
                  props.provider === "Facebook" && (
                    <>
                      <UbiButton
                        onClick={useGoogleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: googleIcon }}
                      />
                      <UbiButton
                        onClick={props.onClose}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: emailIcon }}
                      />
                    </>
                  )}
                {props.attemptedProvider === "Apple" &&
                  props.provider === "email and password" && (
                    <>
                      <UbiButton
                        onClick={useFacebookSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: facebookIcon }}
                      />
                      <UbiButton
                        onClick={useGoogleSignIn}
                        type="neutralDark"
                        style="ghost"
                        size="small"
                        leftIconProps={{ src: googleIcon }}
                      />
                    </>
                  )}
              </Flex>
            </Column>
          )}
          {userChoice && (
            <Flex direction="column" gap="48px">
              <Center mt="24px">
                <Spinner
                  thickness="10px"
                  color="neutral.500"
                  width="80px"
                  height="80px"
                />
              </Center>
              <Text fontSize="16px" color="neutral.800">
                <Trans i18nKey="authentication.redirect_to_auth">
                  You will be redirected to the <b>{props.provider}</b>{" "}
                  authentication pop-up in order to link{" "}
                  <b>{props.attemptedProvider}</b> to your account.
                </Trans>
              </Text>
            </Flex>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
}
