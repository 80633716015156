import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { setTheme } from "@ubirider/pick-component-library";
import reportWebVitals from "./reportWebVitals";
import './i18n';

import theme from "./theme/theme";
import "@ubirider/pick-component-library/dist/index.css";
import "@switchio/st-rp-sdk/index.js";
import "./index.css";

setTheme("external");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
