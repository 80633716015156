import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Text,
  Column,
  TextInput,
  PhoneNumberInput,
  Dropdown,
  FormItem,
  TextAreaInput,
  validatePhoneNumber,
  Checkbox,
  Text as UbiText,
  Row,
} from "@ubirider/pick-component-library";
import { Trans, useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { AuthContext } from "../../AuthProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { getCards } from "../../api/cards";
import { getUserData } from "../../api/user";
import { sendCustomerSupportEmail } from "../../api/email";

import arrowIcon from "../../assets/arrow_right.svg";
import plusIcon from "../../assets/plus_gray.svg";
import trainLoading from "../../assets/train_waiting.svg";
import { NewCardNumberModal } from "../Modals/NewCardNumberModal/NewCardNumberModal";
import checkWhiteIcon from "../../assets/check_circle_white.svg";
import closeWhiteIcon from "../../assets/cross_white_24.svg";
import { Link as ReactLink } from "react-router-dom";
import editIcon from "../../assets/edit.svg";

export const CustomerSupport = () => {
  const { t } = useTranslation();
  const Auth = useContext(AuthContext).user;
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const toast = useToast();
  const toastIdRef = useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [cardOptions, setCardOptions] = useState([]);
  const [data, setData] = useState({});
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedYesNo, setCheckedYesNo] = useState(null);

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { name: null, email: null, phone: null, card: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const cards = await getCards();
      setCardOptions([
        ...cards.map((element) => ({
          label: element.number,
          value: element.number,
        })),
      ]);
      const user = await getUserData();
      setData(user);
      reset({
        name: user?.name,
        email: user?.email ? user?.email : Auth.email,
        phone: null,
        card: null,
      });
      setLoading(false);
      setCheckedYesNo(null);

      console.log(user);
      console.log(cards);
    };
    fetchData();
  }, [Auth.refresh, Auth]);

  const getCells = (value, onChange) => {
    const cells = cardOptions.map((card) => ({
      label:
        card.label.length === 14
          ? card.label.replace(
              /^(\d{4})(\d{2})(\*\*\*\*)(\d{4})$/,
              "$1 $2** **** $4"
            )
          : card.label.match(/.{1,4}/g).join(" "),
      selected: card.value === value,
      onClick: () => onChange(card.value),
    }));
    cells.push({
      label: t("customer_support.other_card_number"),
      onClick: () => {
        onOpen();
      },
      icon: plusIcon,
    });
    return cells;
  };

  const updateNewCard = (number) => {
    setCardOptions([
      ...cardOptions,
      { label: number.replace(/\s/g, ""), value: number.replace(/\s/g, "") },
    ]);
    setValue("card", number.replace(/\s/g, ""));
  };

  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    try {
      await sendCustomerSupportEmail(data, Auth.uid, checkedYesNo);
      setSuccess(true);
      toastIdRef.current = toast({
        position: "top",
        duration: 5000,
        isClosable: true,
        render: () => (
          <Box
            alignItems="center"
            color="white"
            bg="#01AE70"
            border="1px solid #067F5B"
            boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)"
            borderRadius="4px"
          >
            <Flex justifyContent="space-between" gap="16px" padding="16px">
              <Image src={checkWhiteIcon} />
              <Text type="textSM" weight="semibold" css={{ color: "white" }}>
                {t("customer_support.message_sent_toast")}
              </Text>
              <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
            </Flex>
          </Box>
        ),
      });
      reset({
        name: data?.name,
        email: data?.email ? data?.email : Auth.email,
        phone: null,
        card: null,
      });
      setCheckedYesNo(null);
    } catch (error) {
      console.log(error);
    }
    setLoadingSubmit(false);
  };

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  return (
    <Box flex="1">
      <Flex
        bg={{ sm: "", md: "neutral.100", lg: "neutral.100", xl: "neutral.100" }}
        rounded="8px"
        padding={{ sm: "", md: "24px", lg: "24px", xl: "24px" }}
        direction="column"
        flex="1"
        gap="16px"
      >
        <Text type="displayXS" weight="bold">
          {t("customer_support.customer_support")}
        </Text>

        {success ? (
          <Flex
            direction="column"
            minHeight={{
              sm: windowSize.current[1] - 250,
              md: windowSize.current[1] - 250,
              lg: "0",
            }}
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="0 48px"
            gap="24px"
          >
            <Image src={trainLoading} />
            <Text type="textSM" css={{ color: "$neutral800" }}>
              {t("customer_support.message_submitted_confirmation_1")}
            </Text>
            <Text type="textSM" css={{ color: "$neutral800" }}>
              {t("customer_support.message_submitted_confirmation_2")}
            </Text>
            <Text type="textSM" css={{ color: "$neutral800" }}>
              {t("customer_support.message_submitted_confirmation_3")}
            </Text>
            <UbiButton
              onClick={() => setSuccess(false)}
              style="solid"
              type="primary"
              label={t("customer_support.send_new_message")}
            />
          </Flex>
        ) : loading ? (
          <Center minH="387px">
            <Spinner thickness="6px" color="neutral.500" size="xl" />
          </Center>
        ) : (
          <Column
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            css={{ gap: "16px" }}
          >
            <Text type="textSM">
              {t("customer_support.customer_support_description")}
            </Text>
            <Controller
              name="name"
              control={control}
              rules={{ required: t("authentication.required_field") }}
              render={({ field }) => (
                <FormItem required label={t("common.name")} error={errors.name}>
                  <TextInput placeholder={data?.name} {...field} />
                </FormItem>
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: t("authentication.required_field") }}
              render={({ field }) => (
                <FormItem
                  required
                  label={t("common.email")}
                  error={errors.email}
                >
                  <TextInput
                    placeholder={data?.email ? data?.email : Auth.email}
                    {...field}
                  />
                </FormItem>
              )}
            />
            <Flex
              width={{ sm: "100%", md: "50%", lg: "50%", xl: "50%" }}
              minW={{ sm: "", md: "327px", lg: "327px", xl: "327px" }}
            >
              <Controller
                name="phone"
                control={control}
                rules={{
                  validate: {
                    phone_number: (v) =>
                      (v !== null ? validatePhoneNumber(v) : true) ||
                      "Invalid phone number",
                  },
                }}
                render={({ field }) => (
                  <FormItem
                    label={t("customer_support.phone")}
                    error={errors.phone}
                  >
                    <PhoneNumberInput {...field} />
                  </FormItem>
                )}
              />
            </Flex>

            <Flex
              width={{ sm: "100%", md: "50%", lg: "50%", xl: "50%" }}
              minW={{ sm: "", md: "327px", lg: "327px", xl: "327px" }}
            >
              <Controller
                name="card"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormItem
                    label={t("common.card_number")}
                    hint={t("customer_support.card_number_hint")}
                    error={errors.card}
                  >
                    <Dropdown
                      label={
                        value
                          ? cardOptions.find((card) => card.value === value)
                              ?.label.length === 14
                            ? cardOptions
                                .find((card) => card.value === value)
                                ?.label.replace(
                                  /^(\d{4})(\d{2})(\*\*\*\*)(\d{4})$/,
                                  "$1 $2** **** $4"
                                )
                            : cardOptions
                                .find((card) => card.value === value)
                                ?.label.match(/.{1,4}/g)
                                .join(" ")
                          : t("customer_support.card_number_placeholder")
                      }
                      placeholder={!value}
                      cells={getCells(value, onChange)}
                    />
                  </FormItem>
                )}
              />
            </Flex>

            <Controller
              name="message"
              control={control}
              rules={{ required: t("authentication.required_field") }}
              render={({ field: { value, onChange } }) => (
                <FormItem
                  required
                  counter
                  counterValue={value?.length || 0}
                  counterMax={200}
                  label={t("customer_support.message")}
                  error={errors.message}
                >
                  <TextAreaInput
                    value={value}
                    placeholder={t("customer_support.enter_message")}
                    onChange={onChange}
                  />
                </FormItem>
              )}
            />

            <Column css={{ gap: "48px" }}>
              <Column css={{ gap: "24px" }}>
                <Text type="textXS">
                  <Trans i18nKey="customer_support.data_collected_1">
                    The data collected is intended to facilitate contacts
                    between you and Fertagus and will be included in our contact
                    list, in accordance with the Data Protection Legislation in
                    force. To learn more about our Privacy Policy click{" "}
                    <Link
                      sx={{ textDecoration: "underline", fontWeight: "600" }}
                      as={ReactLink}
                      to="https://www.fertagus.pt/pt/Privacidade"
                      isExternal
                    >
                      here
                    </Link>
                    .
                  </Trans>
                </Text>
                <Row css={{ gap: "16px" }}>
                  <Checkbox checked={checked} onChange={setChecked} />
                  <UbiText type="textXS">
                    {t("customer_support.data_authorization_1")}
                  </UbiText>
                </Row>
                <Text type="textXS">
                  <Trans i18nKey="customer_support.data_collected_2">
                    The data collected may be used for profiling, with the aim
                    of offering you a better service and providing you with
                    information and commercial offers according to your tastes
                    and preferences. Following profiling, your data may be used
                    for direct marketing actions, carried out through means that
                    allow messages to be received independently of the
                    intervention of the recipients. Your data will be included
                    in our customer database, in accordance with the Data
                    Protection Legislation in force. To learn more about our
                    Privacy Policy click{" "}
                    <Link
                      sx={{ textDecoration: "underline", fontWeight: "600" }}
                      as={ReactLink}
                      to="https://www.fertagus.pt/pt/Privacidade"
                      isExternal
                    >
                      here
                    </Link>
                    .
                  </Trans>
                </Text>
                <Text type="textXS">
                  {t("customer_support.data_authorization_2")}
                </Text>
                <Column css={{ gap: "16px" }}>
                  <Row css={{ gap: "48px" }}>
                    <Row css={{ gap: "16px" }}>
                      <Checkbox
                        checked={checkedYesNo === "yes"}
                        onChange={() => setCheckedYesNo("yes")}
                      />
                      <UbiText type="textXS">{t("common.yes")}</UbiText>
                    </Row>
                    <Row css={{ gap: "16px" }}>
                      <Checkbox
                        checked={checkedYesNo === "no"}
                        onChange={() => setCheckedYesNo("no")}
                      />
                      <UbiText type="textXS">{t("common.no")}</UbiText>
                    </Row>
                  </Row>
                </Column>
              </Column>

              <Flex>
                <UbiButton
                  submit
                  disabled={!checked}
                  loading={loadingSubmit}
                  style="solid"
                  type="primary"
                  label={t("customer_support.submit")}
                  rightIconProps={{ src: arrowIcon }}
                />
              </Flex>
            </Column>
          </Column>
        )}

        <NewCardNumberModal
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          onSave={updateNewCard}
        />
      </Flex>
    </Box>
  );
};
