import axios from 'axios'
import { getBFFUrl, getToken } from './requests'

export const getBillingInfo = async () => {
  const token = await getToken()

  const res = await axios.get(`${getBFFUrl()}/billingInfo`, {
    headers: {
      'x-access-token': token,
    },
  })

  //console.log(res.data)
  return res.data.data.billingInfo
}

export const setBillingInfo = async billingInfo => {
  const token = await getToken()
  const res = await axios.post(
    `${getBFFUrl()}/billingInfo`,
    {
      profileName: billingInfo.profileName,
      name: billingInfo.name,
      nif: billingInfo.nif,
      address: billingInfo.address,
      postalCode: billingInfo.postalCode,
      city: billingInfo.city,
      country: billingInfo.country,
    },
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  //console.log(res.data)
  return res.data.data.billingInfo
}

export const deleteBillingInfo = async id => {
  const token = await getToken()
  const res = await axios.delete(`${getBFFUrl()}/billingInfo?id=${id}`, {
    headers: {
      'x-access-token': token,
    },
  })

  //console.log(res.data)
  return res.data.data.billingInfo
}

export const setDefaultBillingInfo = async id => {
  const token = await getToken()
  const res = await axios.put(
    `${getBFFUrl()}/billingInfo?id=${id}&default=true`,
    {},
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  //console.log(res.data)
  return res
}

export const editBillingInfo = async (billingInfo, id) => {
  const token = await getToken()
  const res = await axios.put(
    `${getBFFUrl()}/billingInfo?id=${id}`,
    {
      profileName: billingInfo.profileName,
      address: billingInfo.address,
      city: billingInfo.city,
      name: billingInfo.name,
      nif: billingInfo.nif,
      postalCode: billingInfo.postalCode,
      country: billingInfo.country,
    },
    {
      headers: {
        'x-access-token': token,
      },
    }
  )

  //console.log(res.data)
  return res.data.data.billingInfo
}
