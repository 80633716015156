import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import i18next from "i18next";
import { AuthProvider } from "./AuthProvider";
import Landing from "./pages/Landing/Landing";
import { Home } from "./pages/Home";
import { Header } from "./pages/Home/components/Header";
import { Register } from "./pages/Register/Register";
import { Login } from "./pages/Login/Login";
import { Layout } from "./Layout";
import { PrivateRoute } from "./PrivateRoute";
import { Reset } from "./pages/Reset/Reset";
import { NewPassword } from "./pages/NewPassword/NewPassword";
import { Account } from "./pages/Account";
import TravelHistoryPage from "./pages/TravelHistoryPage/TravelHistoryPage";
import { Faqs } from "./pages/Faqs";
import { BillingInfo } from "./pages/BillingInfo";
import { Notifications } from "./pages/Notifications";
import { CustomerSupport } from "./pages/CustomerSupport";
import { UserDeleteInfo } from "./pages/UserDeleteInfo/UserDeleteInfo";

function App() {
  const userLang = navigator.language
    .substring(0, navigator.language.indexOf("-"))
    .toUpperCase();
  console.log(userLang);
  i18next.changeLanguage(userLang);

  return (
    <AuthProvider>
      <Router>
        <>
          <Header />
          <Flex gap="20px" direction={{ md: "row", sm: "column" }}>
            <Routes>
              <Route
                element={
                  <PrivateRoute>
                    <Layout />
                  </PrivateRoute>
                }
              >
                <Route path="/home" exact element={<Home />} />
                <Route path="/home/success" exact element={<Home />} />
                <Route path="/home/fail" exact element={<Home />} />
                <Route
                  path="/travel-history"
                  exact
                  element={<TravelHistoryPage />}
                />
                <Route path="/faqs" exact element={<Faqs />} />
                <Route path="/account" exact element={<Account />} />
                <Route
                  path="/billing-information"
                  exact
                  element={<BillingInfo />}
                />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />
                <Route path="/support" exact element={<CustomerSupport />} />
              </Route>

              <Route element={<Landing />}>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset" element={<Reset />} />
                <Route path="/change-password" element={<NewPassword />} />
              </Route>

              <Route path="/delete-information" element={<UserDeleteInfo />} />

              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </Flex>
        </>
      </Router>
    </AuthProvider>
  );
}

export default App;
