import { Flex, useDisclosure, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Button } from "@ubirider/pick-component-library";
import { SettleAmountModal } from "../../../Modals/SettleAmountModal";
import React, { useState } from "react";

export const PaymentDueBar = ({ trips }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [settledIndexes, updateSettledIndexes] = useState([]);

  const tripsNotPaid = trips.reduce((result, item) => {
    if (item.status.code === 2) {
      const key = item.token;
      if (!result[key]) {
        result[key] = {
          token: key,
          card: item.cardPAN,
          timestamps: [item.date],
          totalAmount: item.amount,
        };
      } else {
        result[key].totalAmount += item.amount;
        result[key].timestamps.push(item.date);
      }
    }
    return result;
  }, {});

  const groupedTrips = Object.values(tripsNotPaid);
  const totalOwed = groupedTrips.reduce(
    (accumulator, item) => accumulator + item.totalAmount,
    0
  );

  return (
    <>
      {groupedTrips.length > 0 && (
        <Flex
          direction="column"
          w="100%"
          border="1px"
          borderColor={
            settledIndexes.length === groupedTrips.length
              ? "warning.200"
              : "danger.200"
          }
          bg={
            settledIndexes.length === groupedTrips.length
              ? "warning.50"
              : "danger.100"
          }
          padding="24px"
          gap="16px"
          rounded="8px"
        >
          <Flex w="100%" justify="space-between">
            <Text fontSize={{ sm: "18px", lg: "24px" }} fontWeight="700">
              {t("travel_history.amount_owed")}
            </Text>
            <Text
              fontSize={{ sm: "18px", lg: "24px" }}
              fontWeight="700"
              color={
                settledIndexes.length === groupedTrips.length
                  ? "warning.500"
                  : "danger.600"
              }
            >
              - {totalOwed.toFixed(2)}€
            </Text>
          </Flex>
          {groupedTrips.length === 1 &&
            settledIndexes.length !== groupedTrips.length && (
              <Text fontSize={{ sm: "13px", lg: "14px" }}>
                {t("travel_history.amount_owed_description_singular")}
              </Text>
            )}
          {groupedTrips.length > 1 &&
            settledIndexes.length !== groupedTrips.length && (
              <Text fontSize={{ sm: "13px", lg: "14px" }}>
                {t("travel_history.amount_owed_description_plural")}
              </Text>
            )}
          {settledIndexes.length === groupedTrips.length && (
            <Text fontSize={{ sm: "14px", lg: "14px" }}>
              {t("travel_history.amount_owed_registered")}
            </Text>
          )}
          <Flex display={{ sm: "none", lg: "flex" }} justify="flex-end">
            {settledIndexes.length === groupedTrips.length ? (
              <Flex
                height="48px"
                padding="0 16px"
                justify="center"
                align="center"
                border="1px"
                borderColor="neutral.300"
                bg="neutral.200"
                rounded="4px"
                cursor="default"
              >
                <Text fontSize="14px" fontWeight="700" color="neutral.500">
                  {t("travel_history.registered")}
                </Text>
              </Flex>
            ) : (
              <Button
                onClick={onOpen}
                type={"danger"}
                style={"solid"}
                label={t("travel_history.settle_amount")}
              />
            )}
          </Flex>
          <Flex display={{ sm: "flex", lg: "none" }}>
            <Button
              onClick={onOpen}
              type={"danger"}
              style={"solid"}
              label={t("travel_history.settle_amount")}
              css={{ width: "100%" }}
            />
          </Flex>
        </Flex>
      )}
      <SettleAmountModal
        trips={groupedTrips}
        settledIndexes={settledIndexes}
        updateSettledIndexes={updateSettledIndexes}
        amount={totalOwed}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};
