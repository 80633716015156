import {
    Center,
    Flex,
    ListItem,
    OrderedList,
} from "@chakra-ui/react";
import { Text } from "@ubirider/pick-component-library";
import {useTranslation} from "react-i18next";

export const UserDeleteInfo = () => {
    const { t } = useTranslation();

    return (
        <Center minH="calc(90vh)" minW="calc(100vw)" padding="0 24px" mt={{sm: "67px", md: "67px", lg: "0"}}>
            <Flex direction="column" gap="48px">
                <Text type="displayXS" weight="bold">{t("delete_info.delete_title")}</Text>
                <Flex direction="column" gap="24px" ml="50px">
                    <OrderedList>
                        <ListItem>{t("delete_info.delete_step_1")}</ListItem>
                        <ListItem>{t("delete_info.delete_step_2")}</ListItem>
                        <ListItem>{t("delete_info.delete_step_3")}</ListItem>
                        <ListItem>{t("delete_info.delete_step_4")}</ListItem>
                        <ListItem>{t("delete_info.delete_step_5")}</ListItem>
                        <ListItem>{t("delete_info.delete_step_6")}</ListItem>
                    </OrderedList>
                    <Text>{t("delete_info.delete_action")}</Text>
                    <Text>{t("delete_info.delete_contact")} <Text weight="bold" css={{textDecoration: "underline"}}><a href="mailto:hello@ubirider.com">hello@ubirider.com</a></Text></Text>
                </Flex>
            </Flex>
        </Center>
    );
};