import {
  Modal,
  ModalOverlay,
  ModalContent,
  Flex,
  Text,
  Image,
  Link,
  Spinner,
  Center,
  useToast,
  Box,
} from "@chakra-ui/react";
import {
  Button as UbiButton,
  Text as UbiText,
} from "@ubirider/pick-component-library";
import { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";
import { getTokenizedURL } from "../../../api/cards";
import { StRpClient } from "@switchio/st-rp-sdk";

import arrowBackIcon from "../../../assets/arrow_left.svg";
import closeButton from "../../../assets/cross.svg";
import dangerIcon from "../../../assets/danger.svg";
import closeWhiteIcon from "../../../assets/cross_white_24.svg";

export function SwitchIOModal(props) {
  const { t } = useTranslation();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const toast = useToast();
  const toastIdRef = useRef();

  const [loading, setLoading] = useState(true);

  function tokenizeInIframeEmbedded(gtwUrl) {
    StRpClient.tokenizeInIframe({
      tokenizationParams: { uri: gtwUrl },
      festparams: { mode: "embedded" },
      parentElement: document.getElementById("container"),
      iframeOptions: {
        style: {
          width: "100%",
          margin: "-38px 0 -52px 0",
          display: "block",
          height: "0",
          border: "0",
          overflow: "hidden",
        },
      },
    }).then((x) => {
      console.log(x);
      if (x.type === "success") {
        console.log("Successfully tokenized.", x);
        props.onClose();
        props.isAdded(true);
        props.setSwitch(false);
        return;
      }
      console.log("Tokenization failed.", x);
      props.onClose();
      props.setSwitch(false);
    });
  }

  useEffect(() => {
    setLoading(true);
  }, [props.onClose, props.isOpen]);

  useEffect(() => {
    const handleClick = async () => {
      setLoading(true);
      try {
        const token = await getTokenizedURL();
        console.log(token);
        tokenizeInIframeEmbedded(token);
        setLoading(false);
      } catch (error) {
        console.log(error);
        props.setSwitch(false);
        props.onClose();
        toastIdRef.current = toast({
          position: "top",
          duration: 5000,
          isClosable: true,
          render: () => (
            <Box
              alignItems="center"
              color="white"
              bg="#E8554F"
              border="1px solid #D23636"
              boxShadow="box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05)"
              borderRadius="4px"
            >
              <Flex justifyContent="space-between" gap="16px" padding="16px">
                <Image src={dangerIcon} />
                <Text fontSize="14px" fontWeight="600">
                  {t("home.payment_method_unable_to_add")}
                </Text>
                <Image cursor="pointer" onClick={close} src={closeWhiteIcon} />
              </Flex>
            </Box>
          ),
        });
      }
    };

    if (props.switch) {
      handleClick();
    }
  }, [props.switch]);

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  function handleClose() {
    props.setSwitch(false);
    props.onClose();
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      size={{ sm: "full", md: "full", lg: "2xl", xl: "2xl" }}
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        margin={{ sm: "67px 0 0 0", md: "67px 0 0 0", lg: "25px", xl: "25px" }}
        padding={{ sm: "16px 24px", md: "16px 24px", lg: "24px", xl: "24px" }}
      >
        <Flex direction="column" gap="24px">
          {windowSize.current[0] < 960 && (
            <Flex alignItems="flex-start">
              <UbiButton
                onClick={props.onClose}
                style="ghost"
                type="neutralLight"
                label={t("authentication.back")}
                leftIconProps={{ src: arrowBackIcon }}
                css={{ paddingLeft: "0" }}
              />
            </Flex>
          )}

          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            zIndex="1000000"
          >
            <Text fontWeight="700" fontSize="24px" flex="1">
              {t("home.add_payment_method")}
            </Text>
            <Flex
              display={{ sm: "none", md: "none", lg: "flex", xl: "flex" }}
              as="button"
              onClick={handleClose}
            >
              <Image src={closeButton} />
            </Flex>
          </Flex>

          {loading && (
            <Center minH="540px">
              <Spinner
                thickness="10px"
                color="neutral.500"
                width="80px"
                height="80px"
              />
            </Center>
          )}

          <Box minH={loading ? "0" : "540px"} id="container"></Box>
          <UbiText type="textSM" css={{ textAlign: "center" }}>
            <Trans i18nKey="home.learn_more_faqs_privacy_policies">
              To learn more about how we use your data consult our{" "}
              <Link
                sx={{ textDecoration: "underline", fontWeight: "600" }}
                as={ReactLink}
                to="/faqs"
                isExternal
              >
                FAQs
              </Link>{" "}
              and the privacy policies of our partners:{" "}
              <Link
                sx={{ textDecoration: "underline", fontWeight: "600" }}
                as={ReactLink}
                to="https://www.credorax.com/privacy"
                isExternal
              >
                Credorax
              </Link>{" "}
              and{" "}
              <Link
                sx={{ textDecoration: "underline", fontWeight: "600" }}
                as={ReactLink}
                to="https://switchio.com/personal-data-protection-policy"
                isExternal
              >
                Switch.io
              </Link>
              .",
            </Trans>
          </UbiText>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
