import {
    Flex,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";

export const VatTag = (props) => {
    const { t } = useTranslation();

    const [vatTag, setTag] = useState(t("common.tax_id"))

    useEffect(() => {
        checkProfile()
    },[props])

    function checkProfile() {
        if (props.text.vatNumber !== "-") {
            props.name.forEach(billing => {
                if (billing.nif === props.text.vatNumber) {
                    setTag(billing.profileName)
                }
            })
        }
        return t("common.tax_id")
    }

    return (
        <Tooltip padding="5px 9px 6px 9px" borderRadius="4px" bg="neutral.600" color="neutral.25" hasArrow label={props?.text?.vatNumber && props.text?.vatNumber !== '-' ? <Text>{t("common.tax_id")}<Text as="b"> {props.text.vatNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')}</Text></Text> : <Text>{t("common.tax_id")}<Text as="b"> 999 999 999</Text></Text>} placement="right">
            <Flex
                display={{ sm: "none", md: "none", lg: "none", xl: "flex" }}
                bg="neutral.25"
                border="1px"
                borderColor="neutral.300"
                borderRadius="4px"
                h="27px"
                paddingInline="8px"
                justifyContent="center"
                align="center"
                w="120px"
            >
                <Text
                    color="neutral.600"
                    fontWeight="700"
                    fontSize="13px"
                    css={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    {vatTag}
                </Text>
            </Flex>
        </Tooltip>
    );
};
